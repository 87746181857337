import React, { useRef } from 'react'
import TawkMessengerReact from '@tawk.to/tawk-messenger-react'
import Message from '@/assets/icons/message.svg'
import styles from './TawkMessenger.module.scss'

const TawkMessenger = () => {
  const tawkMessengerRef = useRef<typeof TawkMessengerReact>()

  const toggle = () => {
    tawkMessengerRef.current?.toggle()
  }

  return (
    <>
      <TawkMessengerReact
        ref={tawkMessengerRef}
        propertyId={process.env.NEXT_PUBLIC_TAWK_PROPERTY_ID}
        widgetId={process.env.NEXT_PUBLIC_TAWK_WIDGET_ID}
      />
      <Message onClick={toggle} className={styles.tawk__button} />
    </>
  )
}

export default TawkMessenger
