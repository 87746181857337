import { useRef, useEffect } from 'react'
import { gsap } from 'gsap'
import { useInView } from 'react-intersection-observer'

import styles from './BorderAnimation.module.scss'
import clsx from 'clsx'

const BorderAnimation = ({ from }: { from: 'start' | 'end' }) => {
  const borderRef = useRef<HTMLDivElement>(null)

  const { ref, inView } = useInView({
    threshold: 0,
  })

  useEffect(() => {
    const ctx = gsap.context(() => {
      if (inView) {
        gsap.fromTo(
          `#border-group-${from} .${styles.border__circle}`,
          {
            opacity: 0,
          },
          {
            duration: 0.2,
            opacity: 1,
            stagger: {
              each: 0.05,
              from: from,
            },
            ease: 'back.in',
          }
        )
      }
    }, borderRef)
    return () => ctx.revert()
  }, [inView, from])

  return (
    <div
      ref={borderRef}
      className={clsx(styles.border, styles[`border__${from}`])}
    >
      <svg
        ref={ref}
        id="border"
        data-name="border"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1243.35 4.86"
      >
        <g id="border-group-end">
          <circle
            className={styles.border__circle}
            cx="983.61"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="971.19"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="958.77"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="946.35"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="933.93"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="921.51"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="909.09"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="896.67"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="884.25"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="871.83"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="859.41"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="846.99"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="834.57"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="822.15"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="809.73"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="797.31"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="784.89"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="772.47"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="760.05"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="747.63"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="735.21"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="722.79"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="710.37"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="697.95"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="685.53"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="673.11"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="660.69"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="648.27"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="635.85"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="623.43"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="611.01"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="598.59"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="586.17"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="573.75"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="561.33"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="548.91"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="536.49"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="524.07"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="511.65"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="499.23"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="486.81"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="474.39"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="461.97"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="449.55"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="437.13"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="424.71"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="412.29"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="399.87"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="387.45"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="375.03"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="362.61"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="350.19"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="337.77"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="325.35"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="312.93"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="300.51"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="288.09"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="275.67"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="263.25"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="250.83"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="238.41"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="225.99"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="213.57"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="201.15"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="188.73"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="176.31"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="163.89"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="151.47"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="139.05"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="126.63"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="114.21"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="101.79"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="89.37"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="76.95"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="64.53"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="52.11"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="39.69"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="27.27"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="14.85"
            cy="2.43"
            r="2"
          />
          <circle className={styles.border__circle} cx="2.43" cy="2.43" r="2" />
        </g>

        <g id="border-group-start">
          <circle
            className={styles.border__circle}
            cx="1232.01"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="1219.59"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="1207.17"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="1194.75"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="1182.33"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="1169.91"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="1157.49"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="1145.07"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="1132.65"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="1120.23"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="1107.81"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="1095.39"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="1082.97"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="1070.55"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="1058.13"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="1045.71"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="1033.29"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="1020.87"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="1008.45"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="996.03"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="983.61"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="971.19"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="958.77"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="946.35"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="933.93"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="921.51"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="909.09"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="896.67"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="884.25"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="871.83"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="859.41"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="846.99"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="834.57"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="822.15"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="809.73"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="797.31"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="784.89"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="772.47"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="760.05"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="747.63"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="735.21"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="722.79"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="710.37"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="697.95"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="685.53"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="673.11"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="660.69"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="648.27"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="635.85"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="623.43"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="611.01"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="598.59"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="586.17"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="573.75"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="561.33"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="548.91"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="536.49"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="524.07"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="511.65"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="499.23"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="486.81"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="474.39"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="461.97"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="449.55"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="437.13"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="424.71"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="412.29"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="399.87"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="387.45"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="375.03"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="362.61"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="350.19"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="337.77"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="325.35"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="312.93"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="300.51"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="288.09"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="275.67"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="263.25"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="250.83"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="238.41"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="225.99"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="213.57"
            cy="2.43"
            r="2"
          />
          <circle
            className={styles.border__circle}
            cx="201.15"
            cy="2.43"
            r="2"
          />
        </g>
      </svg>
    </div>
  )
}

export default BorderAnimation
