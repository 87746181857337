import React from 'react'
import Image from 'next/image'
import { SbBlokData, storyblokEditable } from '@storyblok/react'
import clsx from 'clsx'
import Link from 'next/link'

import styles from './Button.module.scss'

interface LinkProps {
  cached_url: string
  url: string
  linktype: 'story' | 'url'
  target: string
}

export interface IButton {
  align: 'left' | 'right' | 'center'
  buttons?: Array<
    SbBlokData & {
      image: {
        alt: string
        filename: string
      }
      text: string
      link: LinkProps
    }
  >
}

interface ButtonProps {
  blok?: SbBlokData & IButton
  type?: 'submit' | 'button' | 'link'
  children?: React.ReactNode
  btnColor?: string
  align?: 'left' | 'right' | 'center'
  className?: string
  link?: LinkProps
}

const Button = ({
  blok,
  btnColor,
  align,
  children,
  type,
  link,
}: ButtonProps) => {
  return blok?.buttons ? (
    <div
      {...storyblokEditable(blok)}
      className={clsx(styles.button, styles[`button__align__${blok.align}`])}
    >
      {blok.buttons.map((button) => (
        <Link
          key={button._uid}
          href={
            button.link.linktype === 'story'
              ? `/${button.link.cached_url}`
              : button.link.url
          }
          target={button.link.target}
          className={clsx(styles.button__item, styles.button__style__primary)}
        >
          {button.text}
          {button.image && (
            <Image
              className={styles.button__image}
              alt={button.image.alt}
              src={button.image.filename}
              width={24}
              height={24}
            />
          )}
        </Link>
      ))}
    </div>
  ) : type === 'button' || type === 'submit' ? (
    <div className={clsx(styles.button, styles[`button__align__${align}`])}>
      <button
        type={type}
        className={clsx(
          styles.button__item,
          btnColor
            ? styles.button__style__unique
            : styles.button__style__primary
        )}
        style={{ ['--fill-color' as any]: `${btnColor}` }}
      >
        {children}
      </button>
    </div>
  ) : type === 'link' ? (
    <Link
      href={
        link?.linktype === 'story'
          ? `/${link.cached_url}`
          : link?.url
            ? link?.url
            : '/'
      }
      target={link?.target}
      className={clsx(
        styles.button__item,
        btnColor ? styles.button__style__unique : styles.button__style__primary
      )}
      style={{ ['--fill-color' as any]: `${btnColor}` }}
    >
      {children}
    </Link>
  ) : null
}

export default Button
