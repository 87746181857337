import type React from 'react'
import { useEffect, useState } from 'react'
import clsx from 'clsx'
import { type SbBlokData, storyblokEditable } from '@storyblok/react'
import { Navigation, EffectCoverflow, Parallax } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import Image from 'next/image'
import Text from '@/components/storyblok/Text'
import Button from '@/components/storyblok/Button'
import type { ReviwewCardProps } from '@/components/storyblok/ReviewSlider' // is this meant to ref ReviewSlider?

import ArrowRight from '@/assets/icons/arrow-right.svg'
import TechItem from '@/components/Reusuable/TechItem'

import styles from './SliderPortfolio.module.scss'
import { useMediaQuery } from 'react-responsive'

// @TODO: Add json+ld review data
interface SlidesProps {
  backgroundImage: {
    alt: string
    name: string
    filename: string
    focus: string
  }
  techstack: ('next' | 'nuxt' | 'storyblok' | 'contentful' | 'laravel')[]
  logo: {
    alt: string
    name: string
    filename: string
  }
  image: {
    alt: string
    name: string
    filename: string
  }
  review: ReviwewCardProps[]
  buttonColor: {
    color: string
    _uid: string
  }
  textColor: 'light' | 'dark'
  _uid: string
  readMore?: {
    cached_url: string
    url: string
    linktype: 'story' | 'url'
    target: string
    id: string
  }
}

interface SliderPortfolio {
  blok: SbBlokData & {
    slides: SbBlokData & SlidesProps[]
  }
}

const SliderPortfolio = ({ blok }: SliderPortfolio) => {
  const [isVisible, setIsVisible] = useState(false)
  const xs = useMediaQuery({ maxWidth: 420 })
  const sm = useMediaQuery({ minWidth: 421, maxWidth: 640 })
  const md = useMediaQuery({ minWidth: 641, maxWidth: 768 })
  const lg = useMediaQuery({ minWidth: 769, maxWidth: 1024 })
  const xl = useMediaQuery({ minWidth: 1025, maxWidth: 1280 })
  const xxl = useMediaQuery({ minWidth: 1281, maxWidth: 1536 })
  const xxxl = useMediaQuery({ minWidth: 1537, maxWidth: 1920 })
  const xxxxl = useMediaQuery({ minWidth: 1921, maxWidth: 2560 })
  const xxxxxl = useMediaQuery({ minWidth: 2561 })

  const slidesPerView = xs
    ? 1
    : sm
    ? 1
    : md
    ? 1.1
    : lg
    ? 1.2
    : xl
    ? 1.3
    : xxl
    ? 1.4
    : xxxl
    ? 1.5
    : xxxxl
    ? 1.6
    : xxxxxl
    ? 3
    : 1.25

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true)
    }, 1500)

    return () => clearTimeout(timer) // Cleanup the timer on component unmount
  }, [])
  return (
    <div
      className={clsx(
        styles.sliderportfolio,
        isVisible && styles.sliderportfolio__inview
      )}
    >
      <div className={styles.sliderportfolio__container}>
        <Swiper
          {...storyblokEditable(blok)}
          className={styles.sliderportfolio__wrapper}
          slidesPerView={slidesPerView}
          loop={true}
          modules={[Navigation, EffectCoverflow, Parallax]}
          parallax={true}
          effect="coverflow"
          watchSlidesProgress={true}
          initialSlide={1}
          coverflowEffect={{
            depth: 200,
            modifier: 1,
            rotate: 20,
            scale: 1,
            slideShadows: false,
            stretch: 10,
          }}
        >
          {Array.isArray(blok?.slides) &&
            blok?.slides?.map((slide, index) => (
              <SwiperSlide
                className={clsx(
                  styles.sliderportfolio__slide,
                  styles[`sliderportfolio__slide__text__${slide.textColor}`]
                )}
                key={slide._uid}
                virtualIndex={index}
              >
                <div
                  className={styles.sliderportfolio__slide__wrapper}
                  data-swiper-parallax-y="10%"
                >
                  <div
                    className={styles.sliderportfolio__slide__background}
                    style={{
                      ['--fill-color' as keyof React.CSSProperties]: `${slide.buttonColor.color}`,
                    }}
                  >
                    <Image
                      src={`${slide.backgroundImage.filename}/m/1024x0/smart/filters:format(avif)`}
                      priority={true}
                      fill={true}
                      placeholder={
                        process.env.NODE_ENV === 'production' ? 'blur' : 'empty'
                      }
                      alt={slide.logo.alt ?? slide.logo.filename}
                    />
                  </div>
                  <div className={styles.sliderportfolio__slide__content}>
                    <div className={styles.sliderportfolio__slide__portfolio}>
                      <Image
                        src={`${slide.image.filename}/m/600x0/smart/filters:format(avif)`}
                        priority={true}
                        width={800} // @TODO: this should be coming from Storyblok
                        height={800} // @TODO: this should be coming from Storyblok
                        alt={
                          slide.image.alt ? slide.image.alt : slide.image.name
                        }
                      />
                    </div>
                    <div className={styles.sliderportfolio__slide__portfolio}>
                      <div
                        className={styles.sliderportfolio__slide__header}
                        style={{
                          ['--fill-color' as keyof React.CSSProperties]: `${slide.buttonColor.color}`,
                        }}
                      >
                        <div
                          className={
                            styles.sliderportfolio__slide__header__client
                          }
                        >
                          <Image
                            src={`${slide.logo.filename}/m/0x90/smart/filters:format(avif)`}
                            priority={true}
                            width={140} // @TODO: this should be coming from Storyblok
                            height={90} // @TODO: this should be coming from Storyblok
                            alt={slide.logo.alt ?? slide.logo.filename}
                          />
                        </div>
                        <div
                          className={
                            styles.sliderportfolio__slide__header__tech
                          }
                        >
                          {slide?.techstack?.map((tech) => {
                            return <TechItem key={tech} tech={tech} />
                          })}
                        </div>
                      </div>
                      <div
                        className={styles.sliderportfolio__slide__review}
                        style={{
                          ['--fill-color' as keyof React.CSSProperties]: `${slide.buttonColor.color}`,
                        }}
                      >
                        <h2
                          className={
                            styles.sliderportfolio__slide__review__title
                          }
                        >
                          {slide.review[0].heading}
                        </h2>
                        <Text
                          wrapperClass={
                            styles.sliderportfolio__slide__review__content
                          }
                          animate={false}
                          blok={{ text: slide.review[0].text }}
                        />
                        <div
                          className={
                            styles.sliderportfolio__slide__review__company
                          }
                        >
                          <strong>{slide.review[0].organisation}</strong>
                        </div>
                        <div
                          className={
                            styles.sliderportfolio__slide__review__author
                          }
                        >
                          {slide.review[0].author}
                        </div>
                        <div
                          className={
                            styles.sliderportfolio__slide__review__position
                          }
                        >
                          {slide.review[0].position}
                        </div>
                      </div>
                      <div className={styles.sliderportfolio__slide__cta}>
                        {slide.readMore?.id && (
                          <Button
                            link={slide.readMore}
                            type="link"
                            align="right"
                            btnColor={slide.buttonColor.color}
                          >
                            View project
                            <ArrowRight aria-label="Next" />
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
        </Swiper>
      </div>
    </div>
  )
}

export default SliderPortfolio
