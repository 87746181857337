import styles from './Page.module.scss'

import { usePathname } from 'next/navigation'
import {
  storyblokEditable,
  StoryblokComponent,
  SbBlokData,
} from '@storyblok/react/rsc'
import clsx from 'clsx'
import Footer from '../Footer'
import Navbar from '../Navbar'

export interface PageBlok extends SbBlokData {
  pageBackground:
    | 'background__radical'
    | 'background__primary'
    | 'background__secondary'
    | 'background__linear--top'
    | 'background__linear--bottom'
    | 'background__transparent'
  pageSeo: {
    description?: string
    og_description?: string
    og_image?: string
    og_title?: string
    plugin: string
    title: string
    twitter_description?: string
    twitter_image?: string
    twitter_title?: string
    _uid: string
  }
  pageBody?: SbBlokData[]
}

interface PageProps {
  blok: PageBlok
}

const Page = ({ blok }: PageProps) => {
  return (
    <div
      id="main"
      className={clsx(styles.page, blok.pageBackground, styles.page__scroll)}
      {...storyblokEditable(blok)}
    >
      <Navbar background={blok.pageBackground} />
      {blok?.pageBody?.map((nestedBlok) => (
        <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
      ))}
      <Footer />
    </div>
  )
}

export default Page
