import styles from './CaseStudy.module.scss'
import {
  storyblokEditable,
  StoryblokComponent,
  SbBlokData,
  ISbRichtext,
} from '@storyblok/react/rsc'
import Image from 'next/image'
import clsx from 'clsx'
import Footer from '../Footer'
import Navbar from '../Navbar'

import type { CardProps } from '@/components/storyblok/Card'
import Section from '@/components/storyblok/Section'
import Text from '@/components/storyblok/Text'
import LogoNationaDigital from '@/assets/images/national_digital-logo-stacked-mono.png'
import TechItem from '@/components/Reusuable/TechItem'

export interface PageBlok extends SbBlokData {
  pageSeo: {
    description?: string
    og_description?: string
    og_image?: string
    og_title?: string
    plugin: string
    title: string
    twitter_description?: string
    twitter_image?: string
    twitter_title?: string
    _uid: string
  }
  pageTitle: string
  pageSubTitle: ISbRichtext
  logo: {
    alt: string
    name: string
    filename: string
  }
  featureImage: {
    alt: string
    name: string
    filename: string
  }
  techstack: ('next' | 'nuxt' | 'storyblok' | 'contentful' | 'laravel')[]
  cardGroup: {
    cards: Array<CardProps['blok']>
  }[]
  pageContent?: SbBlokData[]
}

interface PageProps {
  blok: PageBlok
}

const CaseStudy = ({ blok }: PageProps) => {
  return (
    <main className={styles.casestudy} {...storyblokEditable(blok)}>
      <Navbar background="background__transparent" />
      <Section
        blok={{
          _uid: 'section-case-study-hero',
          content: [],
          backgroundColor: 'background__linear--top',
          internalName: 'Case Study Hero',
          vAlign: 'center',
          component: 'section',
          backgroundMedia: [],
          sectionHeader: '',
          footer: [],
          borderBottom: false,
          borderTop: false,
          showWaveParticles: false,
        }}
      >
        <div
          className={clsx(
            styles.casestudy__hero,
            styles.casestudy__hero__container
          )}
        >
          <div className={styles.casestudy__hero__eyebrow}>
            <div className={styles.casestudy__hero__eyebrow__logos}>
              <Image
                src={`${blok.logo.filename}/m/0x90`}
                width={140} // @TODO: this should be coming from Storyblok
                height={90} // @TODO: this should be coming from Storyblok
                alt={blok.logo.alt ?? blok.logo.filename}
              />
              <Image
                src={LogoNationaDigital}
                width={140} // @TODO: this should be coming from Storyblok
                height={90} // @TODO: this should be coming from Storyblok
                alt="National Digital Logo"
              />
            </div>
            {blok.techStack && (
              <div className={styles.casestudy__hero__eyebrow__techstack}>
                {Array.isArray(blok.techStack) &&
                  blok.techStack.map(
                    (
                      tech:
                        | 'next'
                        | 'nuxt'
                        | 'storyblok'
                        | 'contentful'
                        | 'laravel'
                    ) => {
                      return <TechItem key={tech} tech={tech} />
                    }
                  )}
              </div>
            )}
          </div>
          <div className={styles.casestudy__hero__title}>
            <h1>{blok.pageTitle}</h1>
            <Text
              wrapperClass={styles.casestudy__hero__subtitle}
              blok={{ text: blok.pageSubTitle }}
            />
          </div>
          <div className={styles.casestudy__hero__cardgroup}>
            {blok.cardGroup[0].cards.map((card) => (
              <div
                className={styles.casestudy__hero__cardgroup__card}
                key={card._uid}
              >
                <h2 className={styles.casestudy__hero__cardgroup__card__header}>
                  {card.heading}
                </h2>
                <Text
                  wrapperClass={styles.casestudy__hero__cardgroup__card__text}
                  blok={{ text: card.text }}
                />
              </div>
            ))}
          </div>
          <div className={styles.casestudy__hero__featureimage}>
            <Image
              src={`${blok.featureImage.filename}/m/1344x0`}
              width={350} // @TODO: this should be coming from Storyblok
              height={230} // @TODO: this should be coming from Storyblok
              alt={blok.featureImage.alt ?? blok.featureImage.filename}
              className={styles.casestudy__hero__featureimage__image}
            />
          </div>
        </div>
      </Section>
      <section className={styles.casestudy__content}>
        {Array.isArray(blok?.pageContent) &&
          blok.pageContent.map((nestedBlok: SbBlokData) => (
            <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
          ))}
      </section>
      <Footer />
    </main>
  )
}

export default CaseStudy
