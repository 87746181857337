import '@/styles/style.scss'
import type { AppProps } from 'next/app'
import StoryblokProvider from '@/components/storyblok/StoryblokProvider'
import TawkMessenger from '@/components/TawkMessenger'
import ReactQueryProvider from '@/providers/ReactQueryProvider'
import {
  randomGrotesque,
  randomGrotesqueSlim,
  sourceCodeVariable,
} from '@/styles/fonts'

function App({ Component, pageProps }: AppProps) {
  return (
    <ReactQueryProvider>
      <StoryblokProvider>
        <main
          className={`${randomGrotesque.variable} ${randomGrotesqueSlim.variable} ${sourceCodeVariable.variable}`}
        >
          <Component {...pageProps} />
        </main>
        <TawkMessenger />
      </StoryblokProvider>
    </ReactQueryProvider>
  )
}

export default App
