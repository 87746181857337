import React, { useState, Fragment } from 'react'
import Image from 'next/image'
import { render } from 'storyblok-rich-text-react-renderer'
import { ISbRichtext, SbBlokData, storyblokEditable } from '@storyblok/react'
import { Dialog, Transition } from '@headlessui/react'

import styles from './Employee.module.scss'

export interface EmployeeProps {
  blok: SbBlokData & {
    image: {
      alt: string
      filename: string
    }
    name: string
    position: string
    bio: ISbRichtext
    _uid: string
  }
}

const Employee = ({ blok }: EmployeeProps) => {
  const [width, height] = blok.image.filename?.split('/')[5]?.split('x') ?? []
  return (
    <div {...storyblokEditable(blok)} className={styles.employee}>
      {blok.image?.filename && (
        <>
          <div className={styles.employee__wrapper}>
            <Image
              className={styles.employee__image}
              alt={blok.image.alt}
              src={`${blok.image.filename}/m/470x0/smart/filters:format(avif)`}
              width={Number(width) ?? 256}
              height={Number(height) ?? 280}
            />
          </div>
          <div className={styles.employee__detail}>
            <span className={styles.employee__name}>{blok.name}</span>
            <span className={styles.employee__position}>{blok.position}</span>
          </div>
        </>
      )}
    </div>
  )
}

export default Employee
