import React from 'react'
import clsx from 'clsx'
import Link from 'next/link'
import { ISbRichtext, SbBlokData, storyblokEditable } from '@storyblok/react'
import { render } from 'storyblok-rich-text-react-renderer'
import padWithLeadingZeros from '@/utils/padWithLeadingZeros'

import styles from './Card.module.scss'

export interface CardProps {
  order?: number
  blok: SbBlokData & {
    heading: string
    borderColor: string
    backgroundColor: string
    text: ISbRichtext
    link: {
      cached_url: string
      url: string
      linktype: 'story' | 'url'
      target: string
    }
  }
}

const Card = ({ blok, order }: CardProps) => {
  const link =
    blok.link.linktype === 'story'
      ? blok.link.cached_url
        ? `/${blok.link.cached_url}`
        : ''
      : blok.link.url
  return (
    <Link
      {...storyblokEditable(blok)}
      href={link}
      target={blok.link.target}
      className={clsx(
        styles.card,
        styles[`card__border__${blok.borderColor}`],
        styles[`card__background__${blok.backgroundColor}`]
      )}
    >
      {order && <small>/ {padWithLeadingZeros(order, 2)}</small>}
      <h2 className={styles.card__heading}>{blok.heading}</h2>
      {render(blok.text)}
    </Link>
  )
}

export default Card
