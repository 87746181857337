import Link from 'next/link'
import LinkedInIcon from '@/assets/icons/linkedin.svg'
import Logo from '@/assets/icons/national-digital.svg'
import styles from './Footer.module.scss'

const Footer: React.FC = () => {
  return (
    <section>
      <div className={styles.acknowledgement__wrapper}>
        <div className={styles.acknowledgement__container}>
          <h2 className={styles.acknowledgement__text}>National Digital is a <span className={styles.acknowledgement__text__highlight}>Gold Coast</span> web development agency.</h2>
          <div className={styles.acknowledgement__text}>We acknowledge and pay respect to the land and the traditional families of the <span className={styles.acknowledgement__text__highlight}>Yugambeh region</span> of South East Queensland and their Elders past, present and emerging. The Yugambeh region includes families that identify as Kombumerri, Mununjali, Wangerriburra and others.</div>
          <div className={styles.acknowledgement__credit}>Acknowledgement provided by the <Link href="https://www.yugambeh.com/" target="_blank" rel="noopener noreferrer">Yugambeh Museum, Language and Heritage Research Centre.</Link></div>
        </div>
      </div>
      <div className={styles.footer__wrapper}>
        <div className={styles.footer__container}>
          <div className={styles.footer__section}>
            <div className={styles.footer__logo}>
              <Logo
                aria-label="National Digital"
                width={32}
                height={40}
              />
            </div>
            <div className={styles.footer__copyright}>
              &copy; 2016-{new Date().getFullYear()} National Digital
            </div>
          </div>
          <div className={styles.footer__section}>
            <div className={styles.footer__menu}>
              <Link href="tel:+611300800855">1300 800 855</Link>
              <Link href="https://www.linkedin.com/company/national-digital/" target="_blank" rel="noopener noreferrer">
                <span className={styles.footer__menu__icon}>
                  <LinkedInIcon
                    aria-label="LinkedIn"
                    width={32}
                    height={32}
                  />
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Footer
