import React, { useEffect, useRef, useState } from 'react'
import Image from 'next/image'
import { contentInView } from '@/utils/contentInView'
import {
  type ISbRichtext,
  type SbBlokData,
  StoryblokComponent,
  storyblokEditable,
} from '@storyblok/react'

import styles from './Text.module.scss'
import clsx from 'clsx'
import {
  MARK_BOLD,
  MARK_UNDERLINE,
  render,
} from 'storyblok-rich-text-react-renderer'

interface TextProps {
  blok: SbBlokData & {
    image?: {
      alt: string
      filename: string
    }
    text: ISbRichtext
    alignment?: 'left' | 'center' | 'right' | 'justify'
  }
  wrapperClass?: string
  animate?: boolean
}

const Text = ({ blok, wrapperClass, animate = true }: TextProps) => {
  const divRef = useRef<HTMLDivElement>(null)
  const [isIntersecting, setIsIntersecting] = useState(false)

  useEffect(() => {
    if (animate) {
      const handleIntersection: IntersectionObserverCallback = (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting) {
            setIsIntersecting(true)
          }
        }
      }

      if (divRef.current) {
        const observer = contentInView(divRef.current, handleIntersection)
        return () => observer.disconnect()
      }
    }
  }, [animate])
  return (
    <div
      {...storyblokEditable(blok)}
      className={clsx(
        wrapperClass,
        styles.text,
        animate && styles.text__animate,
        animate && isIntersecting && styles.text__inview,
        blok.alignment && styles[`text__align__${blok.alignment}`]
      )}
      ref={divRef}
    >
      {blok.image?.filename && (
        <Image
          className={styles.image}
          alt={blok.image.alt}
          src={blok.image.filename}
          width={40}
          height={40}
        />
      )}
      {render(blok.text, {
        defaultBlokResolver: (name, props) => {
          const blok = { ...props, component: name }
          return <StoryblokComponent blok={blok} key={props._uid} />
        },
        markResolvers: {
          [MARK_BOLD]: (children) => <strong>{children}</strong>,
          [MARK_UNDERLINE]: (children) => (
            <span className={styles.text__highlight}>{children}</span>
          ),
        },
      })}
    </div>
  )
}

export default Text
