import styles from './Navbar.module.scss'
import clsx from 'clsx'
import Logo from '@/assets/icons/national-digital.svg'
import { Menu, Transition } from '@headlessui/react'
import Link from 'next/link'
import { Fragment, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { gsap } from 'gsap'
import ServicesIcon from './icons/ServicesIcon'
import TeamIcon from './icons/TeamIcon'
import ContactsIcon from './icons/ContactsIcon'

const useIsomorphicLayoutEffect =
  typeof window !== 'undefined' ? useLayoutEffect : useEffect

const menus = [
  {
    title: 'Our Work',
    icon: ServicesIcon,
    path: '/#7f8e43ff-6fb3-4ddf-85fb-1c8ec60c03a9',
  },
  {
    title: 'Who We Are',
    icon: TeamIcon,
    path: '/#dee0ed03-3627-47bc-a5b5-b31d942c8db6',
  },
  {
    title: 'Contact Us',
    icon: ContactsIcon,
    path: '/#7ac89b10-8d5c-4fde-a2de-194c41623431',
  },
]

type Props = {
  background:
    | 'background__radical'
    | 'background__primary'
    | 'background__secondary'
    | 'background__linear--top'
    | 'background__linear--bottom'
    | 'background__transparent'
}

const Navbar = (props: Props) => {
  const prevScrollY = useRef(0)
  const [isScrollingUp, setIsScrollingUp] = useState(false)
  const [isScrolledTop, setIsScrolledTop] = useState(true)
  const hamburgerRef = useRef<HTMLButtonElement>(null)

  const onOpen = () => {
    const bodyElem = document.querySelector('body')
    bodyElem?.classList.add('menu-open')
    prevScrollY.current = window.scrollY
    window.scrollTo({ top: 20, behavior: 'instant' })
  }

  const onClose = () => {
    const bodyElem = document.querySelector('body')
    bodyElem?.classList.remove('menu-open')
    window.scrollTo({ top: prevScrollY.current, behavior: 'instant' })
  }

  useEffect(() => {
    const main = document.querySelector('#main')
  
    const handleScroll = () => {
      const scrollTop = main?.scrollTop ?? window.scrollY
      setIsScrollingUp(scrollTop < prevScrollY.current)
      setIsScrolledTop(scrollTop === 0)
      prevScrollY.current = scrollTop <= 0 ? 0 : scrollTop
    }
  
    const target = main || window
    target.addEventListener('scroll', handleScroll)
  
    return () => {
      target.removeEventListener('scroll', handleScroll)
    }
  }, [])



  useIsomorphicLayoutEffect(() => {
    const ctx = gsap.context(() => {
      const tl = gsap
        .timeline({ paused: true, reversed: true })

        .to(
          '.top',
          { duration: 0.2, y: '-6px', transformOrigin: '50% 50%' },
          'burg'
        )
        .to(
          '.bot',
          { duration: 0.2, y: '6px', transformOrigin: '50% 50%' },
          'burg'
        )
        .to(
          '.mid',
          { duration: 0.2, scale: 0.1, transformOrigin: '50% 50%' },
          'burg'
        )
        .add('rotate')
        .to('.top', { duration: 0.2, y: '6px' }, 'rotate')
        .to('.bot', { duration: 0.2, y: '-6px' }, 'rotate')
        .to(
          '.top',
          { duration: 0.2, rotationZ: 45, transformOrigin: '50% 50%' },
          'rotate'
        )
        .to(
          '.bot',
          { duration: 0.2, rotationZ: -45, transformOrigin: '50% 50%' },
          'rotate'
        )
        .set('#hamburger .mid', { opacity: 0 }) //temp fix for stupid iOS rotate y bug

      hamburgerRef.current?.addEventListener('click', () => {
        tl.reversed() ? tl.play() : tl.reverse()
      })
    }, hamburgerRef)
    return () => ctx.revert()
  }, [])

  return (
    <Menu as="div">
      {({ open }) => (
        <>
          <nav
            className={clsx(
              styles.navbar__wrapper,
              open && styles.navbar__wrapper__open,
              isScrollingUp &&
                !isScrolledTop &&
                clsx(
                  styles.navbar__wrapper__isvisible,
                  styles.navbar__wrapper__hasbackground
                ),
              isScrolledTop && styles.navbar__wrapper__isvisible
            )}
          >
            <div className={styles.navbar__container}>
              <Link href="/">
                <Logo aria-label="National Digital" width={32} height={40} />
              </Link>
              <div className={styles.navbar__desktop__menu}>
                {menus.map((menu) => (
                  <Link
                    key={menu.path}
                    className={styles.navbar__desktop__menu__item}
                    href={menu.path}
                  >
                    {/* {<menu.icon />} */}
                    {menu.title}
                  </Link>
                ))}
              </div>

              <Menu.Button
                type="button"
                aria-label="Menu"
                ref={hamburgerRef}
                className={styles.navbar__menu}
                onClick={() => {
                  if (!open) {
                    onOpen()
                  } else {
                    onClose()
                  }
                }}
              >
                <svg
                  id="hamburger"
                  aria-label="Menu"
                  className={clsx(
                    styles.navbar__menu__hamburger,
                    open && styles.navbar__menu__hamburger__open
                  )}
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 17 14"
                >
                  <path
                    d="M1 1h15"
                    className="top"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeWidth="2"
                  />
                  <path
                    d="M1 7h9"
                    className="mid"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeWidth="2"
                  />
                  <path
                    d="M1 13h15"
                    className="bot"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeWidth="2"
                  />
                </svg>
              </Menu.Button>
            </div>
          </nav>

          <Transition
            as={Fragment}
            enter={styles.transition__enter}
            enterFrom={styles.transition__initial}
            enterTo={styles.transition__visible}
            leave={styles.transition__leave}
            leaveFrom={styles.transition__visible}
            leaveTo={styles.transition__initial}
          >
            <Menu.Items
              className={clsx(styles.navbar__menu__items, props.background)}
            >
              <MenuItems onClose={onClose} background={props.background} />
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  )
}

type MenusProps = {
  onClose: () => void
  background: string
}

const MenuItems = (props: MenusProps) => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const ctx = gsap.context(() => {
      gsap.fromTo(
        `.${styles.navbar__menu__item}`,
        {
          opacity: 0,
        },
        {
          duration: 1,
          opacity: 1,
          stagger: {
            each: 0.3,
          },
          ease: 'back.in',
        }
      )
    }, ref)
    return () => ctx.revert()
  }, [])

  return (
    <div ref={ref}>
      {menus.map((menu) => (
        <Menu.Item key={menu.path}>
          {({ active }) => (
            <Link
              className={clsx(
                styles.navbar__menu__item,
                active && styles.navbar__menu__item__active
              )}
              href={menu.path}
              onClick={props.onClose}
            >
              {<menu.icon />}
              {menu.title}
            </Link>
          )}
        </Menu.Item>
      ))}
    </div>
  )
}

export default Navbar
