import { useRef, useEffect } from 'react'
import { gsap } from 'gsap'
import styles from './DotsAnimation.module.scss'

const DotsAnimation = ({ inView }: { inView: boolean }) => {
  const ref = useRef<HTMLDivElement>(null)
  useEffect(() => {
    const ctx = gsap.context(() => {
      if (inView) {
        gsap.fromTo(
          '#dots-group',
          {
            opacity: 0,
          },
          {
            delay: 0.3,
            duration: 5,
            opacity: 0.47,
          }
        )
        gsap.fromTo(
          `.${styles.dots__circle}`,
          {
            scale: 0.25,
          },
          {
            delay: 0.3,
            duration: 5,
            scale: 1,
          }
        )
      }
    }, ref)
    return () => ctx.revert()
  }, [inView])

  return (
    <div ref={ref} className={styles.dots}>
      {/* <svg
        id="dots"
        data-name="dots"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1186.77 458.91"
      >
        <g id="dots-group" style={{ opacity: 0 }}>
          <g>
            <circle
              className={styles.dots__circle}
              cx="1181.91"
              cy="4.86"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1158.42"
              cy="4.86"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1134.93"
              cy="4.86"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1111.44"
              cy="4.86"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1087.95"
              cy="4.86"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1064.46"
              cy="4.86"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1040.97"
              cy="4.86"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1017.48"
              cy="4.86"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="993.99"
              cy="4.86"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="970.5"
              cy="4.86"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="947.01"
              cy="4.86"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="923.52"
              cy="4.86"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="900.03"
              cy="4.86"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="876.54"
              cy="4.86"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="853.05"
              cy="4.86"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="829.56"
              cy="4.86"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="806.07"
              cy="4.86"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="782.58"
              cy="4.86"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="759.09"
              cy="4.86"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="735.6"
              cy="4.86"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="712.11"
              cy="4.86"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="688.62"
              cy="4.86"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="665.13"
              cy="4.86"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="641.64"
              cy="4.86"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="618.15"
              cy="4.86"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="594.66"
              cy="4.86"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="571.17"
              cy="4.86"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="547.68"
              cy="4.86"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="524.19"
              cy="4.86"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="500.7"
              cy="4.86"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="477.21"
              cy="4.86"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="453.72"
              cy="4.86"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="430.23"
              cy="4.86"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="406.74"
              cy="4.86"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="383.25"
              cy="4.86"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="359.76"
              cy="4.86"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="336.27"
              cy="4.86"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="312.78"
              cy="4.86"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="289.29"
              cy="4.86"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="265.8"
              cy="4.86"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="242.31"
              cy="4.86"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="218.82"
              cy="4.86"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="195.33"
              cy="4.86"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="171.84"
              cy="4.86"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="148.35"
              cy="4.86"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="124.86"
              cy="4.86"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="101.37"
              cy="4.86"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="77.88"
              cy="4.86"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="54.39"
              cy="4.86"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="30.9"
              cy="4.86"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="7.41"
              cy="4.86"
              r="4.86"
            />
          </g>
          <g>
            <circle
              className={styles.dots__circle}
              cx="1181.79"
              cy="26.25"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1158.3"
              cy="26.25"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1134.81"
              cy="26.25"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1111.32"
              cy="26.25"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1087.83"
              cy="26.25"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1064.34"
              cy="26.25"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1040.85"
              cy="26.25"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1017.36"
              cy="26.25"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="993.87"
              cy="26.25"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="970.38"
              cy="26.25"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="946.89"
              cy="26.25"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="923.4"
              cy="26.25"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="899.91"
              cy="26.25"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="876.42"
              cy="26.25"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="852.93"
              cy="26.25"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="829.44"
              cy="26.25"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="805.95"
              cy="26.25"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="782.46"
              cy="26.25"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="758.97"
              cy="26.25"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="735.48"
              cy="26.25"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="711.99"
              cy="26.25"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="688.5"
              cy="26.25"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="665.01"
              cy="26.25"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="641.52"
              cy="26.25"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="618.03"
              cy="26.25"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="594.54"
              cy="26.25"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="571.05"
              cy="26.25"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="547.56"
              cy="26.25"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="524.07"
              cy="26.25"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="500.58"
              cy="26.25"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="477.09"
              cy="26.25"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="453.6"
              cy="26.25"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="430.11"
              cy="26.25"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="406.62"
              cy="26.25"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="383.13"
              cy="26.25"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="359.64"
              cy="26.25"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="336.15"
              cy="26.25"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="312.66"
              cy="26.25"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="289.17"
              cy="26.25"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="265.68"
              cy="26.25"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="242.19"
              cy="26.25"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="218.7"
              cy="26.25"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="195.21"
              cy="26.25"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="171.72"
              cy="26.25"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="148.23"
              cy="26.25"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="124.74"
              cy="26.25"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="101.25"
              cy="26.25"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="77.76"
              cy="26.25"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="54.27"
              cy="26.25"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="30.78"
              cy="26.25"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="7.29"
              cy="26.25"
              r="4.86"
            />
          </g>
          <g>
            <circle
              className={styles.dots__circle}
              cx="1181.67"
              cy="47.64"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1158.18"
              cy="47.64"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1134.69"
              cy="47.64"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1111.2"
              cy="47.64"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1087.71"
              cy="47.64"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1064.22"
              cy="47.64"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1040.73"
              cy="47.64"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1017.24"
              cy="47.64"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="993.75"
              cy="47.64"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="970.26"
              cy="47.64"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="946.77"
              cy="47.64"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="923.28"
              cy="47.64"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="899.79"
              cy="47.64"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="876.3"
              cy="47.64"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="852.81"
              cy="47.64"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="829.32"
              cy="47.64"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="805.83"
              cy="47.64"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="782.34"
              cy="47.64"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="758.85"
              cy="47.64"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="735.36"
              cy="47.64"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="711.87"
              cy="47.64"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="688.38"
              cy="47.64"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="664.89"
              cy="47.64"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="641.4"
              cy="47.64"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="617.91"
              cy="47.64"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="594.42"
              cy="47.64"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="570.93"
              cy="47.64"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="547.44"
              cy="47.64"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="523.95"
              cy="47.64"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="500.46"
              cy="47.64"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="476.97"
              cy="47.64"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="453.48"
              cy="47.64"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="429.99"
              cy="47.64"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="406.5"
              cy="47.64"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="383.01"
              cy="47.64"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="359.52"
              cy="47.64"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="336.03"
              cy="47.64"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="312.54"
              cy="47.64"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="289.05"
              cy="47.64"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="265.56"
              cy="47.64"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="242.07"
              cy="47.64"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="218.58"
              cy="47.64"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="195.09"
              cy="47.64"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="171.6"
              cy="47.64"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="148.11"
              cy="47.64"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="124.62"
              cy="47.64"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="101.13"
              cy="47.64"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="77.64"
              cy="47.64"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="54.15"
              cy="47.64"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="30.66"
              cy="47.64"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="7.17"
              cy="47.64"
              r="4.86"
            />
          </g>
          <g>
            <circle
              className={styles.dots__circle}
              cx="1181.55"
              cy="69.03"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1158.06"
              cy="69.03"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1134.57"
              cy="69.03"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1111.08"
              cy="69.03"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1087.59"
              cy="69.03"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1064.1"
              cy="69.03"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1040.61"
              cy="69.03"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1017.12"
              cy="69.03"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="993.63"
              cy="69.03"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="970.14"
              cy="69.03"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="946.65"
              cy="69.03"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="923.16"
              cy="69.03"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="899.67"
              cy="69.03"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="876.18"
              cy="69.03"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="852.69"
              cy="69.03"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="829.2"
              cy="69.03"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="805.71"
              cy="69.03"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="782.22"
              cy="69.03"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="758.73"
              cy="69.03"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="735.24"
              cy="69.03"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="711.75"
              cy="69.03"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="688.26"
              cy="69.03"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="664.77"
              cy="69.03"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="641.28"
              cy="69.03"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="617.79"
              cy="69.03"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="594.3"
              cy="69.03"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="570.81"
              cy="69.03"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="547.32"
              cy="69.03"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="523.83"
              cy="69.03"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="500.34"
              cy="69.03"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="476.85"
              cy="69.03"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="453.36"
              cy="69.03"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="429.87"
              cy="69.03"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="406.38"
              cy="69.03"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="382.89"
              cy="69.03"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="359.4"
              cy="69.03"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="335.91"
              cy="69.03"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="312.42"
              cy="69.03"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="288.93"
              cy="69.03"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="265.44"
              cy="69.03"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="241.95"
              cy="69.03"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="218.46"
              cy="69.03"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="194.97"
              cy="69.03"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="171.48"
              cy="69.03"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="147.99"
              cy="69.03"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="124.5"
              cy="69.03"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="101.01"
              cy="69.03"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="77.52"
              cy="69.03"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="54.03"
              cy="69.03"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="30.54"
              cy="69.03"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="7.05"
              cy="69.03"
              r="4.86"
            />
          </g>
          <g>
            <circle
              className={styles.dots__circle}
              cx="1181.43"
              cy="90.42"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1157.94"
              cy="90.42"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1134.45"
              cy="90.42"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1110.96"
              cy="90.42"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1087.47"
              cy="90.42"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1063.98"
              cy="90.42"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1040.49"
              cy="90.42"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1017"
              cy="90.42"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="993.51"
              cy="90.42"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="970.02"
              cy="90.42"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="946.53"
              cy="90.42"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="923.04"
              cy="90.42"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="899.55"
              cy="90.42"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="876.06"
              cy="90.42"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="852.57"
              cy="90.42"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="829.08"
              cy="90.42"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="805.59"
              cy="90.42"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="782.1"
              cy="90.42"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="758.61"
              cy="90.42"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="735.12"
              cy="90.42"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="711.63"
              cy="90.42"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="688.14"
              cy="90.42"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="664.65"
              cy="90.42"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="641.16"
              cy="90.42"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="617.67"
              cy="90.42"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="594.18"
              cy="90.42"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="570.69"
              cy="90.42"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="547.2"
              cy="90.42"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="523.71"
              cy="90.42"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="500.22"
              cy="90.42"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="476.73"
              cy="90.42"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="453.24"
              cy="90.42"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="429.75"
              cy="90.42"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="406.26"
              cy="90.42"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="382.77"
              cy="90.42"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="359.28"
              cy="90.42"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="335.79"
              cy="90.42"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="312.3"
              cy="90.42"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="288.81"
              cy="90.42"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="265.32"
              cy="90.42"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="241.83"
              cy="90.42"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="218.34"
              cy="90.42"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="194.85"
              cy="90.42"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="171.36"
              cy="90.42"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="147.87"
              cy="90.42"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="124.38"
              cy="90.42"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="100.89"
              cy="90.42"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="77.4"
              cy="90.42"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="53.91"
              cy="90.42"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="30.42"
              cy="90.42"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="6.93"
              cy="90.42"
              r="4.86"
            />
          </g>
          <g>
            <circle
              className={styles.dots__circle}
              cx="1181.3"
              cy="111.81"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1157.81"
              cy="111.81"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1134.32"
              cy="111.81"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1110.83"
              cy="111.81"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1087.34"
              cy="111.81"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1063.85"
              cy="111.81"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1040.36"
              cy="111.81"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1016.87"
              cy="111.81"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="993.38"
              cy="111.81"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="969.89"
              cy="111.81"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="946.4"
              cy="111.81"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="922.91"
              cy="111.81"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="899.42"
              cy="111.81"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="875.93"
              cy="111.81"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="852.44"
              cy="111.81"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="828.95"
              cy="111.81"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="805.46"
              cy="111.81"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="781.97"
              cy="111.81"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="758.48"
              cy="111.81"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="734.99"
              cy="111.81"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="711.5"
              cy="111.81"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="688.01"
              cy="111.81"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="664.52"
              cy="111.81"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="641.03"
              cy="111.81"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="617.54"
              cy="111.81"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="594.05"
              cy="111.81"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="570.56"
              cy="111.81"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="547.07"
              cy="111.81"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="523.58"
              cy="111.81"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="500.09"
              cy="111.81"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="476.6"
              cy="111.81"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="453.11"
              cy="111.81"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="429.62"
              cy="111.81"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="406.13"
              cy="111.81"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="382.64"
              cy="111.81"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="359.15"
              cy="111.81"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="335.66"
              cy="111.81"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="312.17"
              cy="111.81"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="288.68"
              cy="111.81"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="265.19"
              cy="111.81"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="241.7"
              cy="111.81"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="218.21"
              cy="111.81"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="194.72"
              cy="111.81"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="171.23"
              cy="111.81"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="147.74"
              cy="111.81"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="124.25"
              cy="111.81"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="100.76"
              cy="111.81"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="77.27"
              cy="111.81"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="53.78"
              cy="111.81"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="30.29"
              cy="111.81"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="6.8"
              cy="111.81"
              r="4.86"
            />
          </g>
          <g>
            <circle
              className={styles.dots__circle}
              cx="1181.18"
              cy="133.2"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1157.69"
              cy="133.2"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1134.2"
              cy="133.2"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1110.71"
              cy="133.2"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1087.22"
              cy="133.2"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1063.73"
              cy="133.2"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1040.24"
              cy="133.2"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1016.75"
              cy="133.2"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="993.26"
              cy="133.2"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="969.77"
              cy="133.2"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="946.28"
              cy="133.2"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="922.79"
              cy="133.2"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="899.3"
              cy="133.2"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="875.81"
              cy="133.2"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="852.32"
              cy="133.2"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="828.83"
              cy="133.2"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="805.34"
              cy="133.2"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="781.85"
              cy="133.2"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="758.36"
              cy="133.2"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="734.87"
              cy="133.2"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="711.38"
              cy="133.2"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="687.89"
              cy="133.2"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="664.4"
              cy="133.2"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="640.91"
              cy="133.2"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="617.42"
              cy="133.2"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="593.93"
              cy="133.2"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="570.44"
              cy="133.2"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="546.95"
              cy="133.2"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="523.46"
              cy="133.2"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="499.97"
              cy="133.2"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="476.48"
              cy="133.2"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="452.99"
              cy="133.2"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="429.5"
              cy="133.2"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="406.01"
              cy="133.2"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="382.52"
              cy="133.2"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="359.03"
              cy="133.2"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="335.54"
              cy="133.2"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="312.05"
              cy="133.2"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="288.56"
              cy="133.2"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="265.07"
              cy="133.2"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="241.58"
              cy="133.2"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="218.09"
              cy="133.2"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="194.6"
              cy="133.2"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="171.11"
              cy="133.2"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="147.62"
              cy="133.2"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="124.13"
              cy="133.2"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="100.64"
              cy="133.2"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="77.15"
              cy="133.2"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="53.66"
              cy="133.2"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="30.17"
              cy="133.2"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="6.68"
              cy="133.2"
              r="4.86"
            />
          </g>
          <g>
            <circle
              className={styles.dots__circle}
              cx="1181.06"
              cy="154.59"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1157.57"
              cy="154.59"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1134.08"
              cy="154.59"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1110.59"
              cy="154.59"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1087.1"
              cy="154.59"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1063.61"
              cy="154.59"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1040.12"
              cy="154.59"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1016.63"
              cy="154.59"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="993.14"
              cy="154.59"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="969.65"
              cy="154.59"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="946.16"
              cy="154.59"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="922.67"
              cy="154.59"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="899.18"
              cy="154.59"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="875.69"
              cy="154.59"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="852.2"
              cy="154.59"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="828.71"
              cy="154.59"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="805.22"
              cy="154.59"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="781.73"
              cy="154.59"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="758.24"
              cy="154.59"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="734.75"
              cy="154.59"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="711.26"
              cy="154.59"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="687.77"
              cy="154.59"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="664.28"
              cy="154.59"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="640.79"
              cy="154.59"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="617.3"
              cy="154.59"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="593.81"
              cy="154.59"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="570.32"
              cy="154.59"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="546.83"
              cy="154.59"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="523.34"
              cy="154.59"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="499.85"
              cy="154.59"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="476.36"
              cy="154.59"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="452.87"
              cy="154.59"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="429.38"
              cy="154.59"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="405.89"
              cy="154.59"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="382.4"
              cy="154.59"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="358.91"
              cy="154.59"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="335.42"
              cy="154.59"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="311.93"
              cy="154.59"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="288.44"
              cy="154.59"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="264.95"
              cy="154.59"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="241.46"
              cy="154.59"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="217.97"
              cy="154.59"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="194.48"
              cy="154.59"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="170.99"
              cy="154.59"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="147.5"
              cy="154.59"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="124.01"
              cy="154.59"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="100.52"
              cy="154.59"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="77.03"
              cy="154.59"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="53.54"
              cy="154.59"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="30.05"
              cy="154.59"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="6.56"
              cy="154.59"
              r="4.86"
            />
          </g>
          <g>
            <circle
              className={styles.dots__circle}
              cx="1180.94"
              cy="175.98"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1157.45"
              cy="175.98"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1133.96"
              cy="175.98"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1110.47"
              cy="175.98"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1086.98"
              cy="175.98"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1063.49"
              cy="175.98"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1040"
              cy="175.98"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1016.51"
              cy="175.98"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="993.02"
              cy="175.98"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="969.53"
              cy="175.98"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="946.04"
              cy="175.98"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="922.55"
              cy="175.98"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="899.06"
              cy="175.98"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="875.57"
              cy="175.98"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="852.08"
              cy="175.98"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="828.59"
              cy="175.98"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="805.1"
              cy="175.98"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="781.61"
              cy="175.98"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="758.12"
              cy="175.98"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="734.63"
              cy="175.98"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="711.14"
              cy="175.98"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="687.65"
              cy="175.98"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="664.16"
              cy="175.98"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="640.67"
              cy="175.98"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="617.18"
              cy="175.98"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="593.69"
              cy="175.98"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="570.2"
              cy="175.98"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="546.71"
              cy="175.98"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="523.22"
              cy="175.98"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="499.73"
              cy="175.98"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="476.24"
              cy="175.98"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="452.75"
              cy="175.98"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="429.26"
              cy="175.98"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="405.77"
              cy="175.98"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="382.28"
              cy="175.98"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="358.79"
              cy="175.98"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="335.3"
              cy="175.98"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="311.81"
              cy="175.98"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="288.32"
              cy="175.98"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="264.83"
              cy="175.98"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="241.34"
              cy="175.98"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="217.85"
              cy="175.98"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="194.36"
              cy="175.98"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="170.87"
              cy="175.98"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="147.38"
              cy="175.98"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="123.89"
              cy="175.98"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="100.4"
              cy="175.98"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="76.91"
              cy="175.98"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="53.42"
              cy="175.98"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="29.93"
              cy="175.98"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="6.44"
              cy="175.98"
              r="4.86"
            />
          </g>
          <g>
            <circle
              className={styles.dots__circle}
              cx="1180.82"
              cy="197.37"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1157.33"
              cy="197.37"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1133.84"
              cy="197.37"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1110.35"
              cy="197.37"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1086.86"
              cy="197.37"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1063.37"
              cy="197.37"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1039.88"
              cy="197.37"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1016.39"
              cy="197.37"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="992.9"
              cy="197.37"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="969.41"
              cy="197.37"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="945.92"
              cy="197.37"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="922.43"
              cy="197.37"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="898.94"
              cy="197.37"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="875.45"
              cy="197.37"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="851.96"
              cy="197.37"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="828.47"
              cy="197.37"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="804.98"
              cy="197.37"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="781.49"
              cy="197.37"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="758"
              cy="197.37"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="734.51"
              cy="197.37"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="711.02"
              cy="197.37"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="687.53"
              cy="197.37"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="664.04"
              cy="197.37"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="640.55"
              cy="197.37"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="617.06"
              cy="197.37"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="593.57"
              cy="197.37"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="570.08"
              cy="197.37"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="546.59"
              cy="197.37"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="523.1"
              cy="197.37"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="499.61"
              cy="197.37"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="476.12"
              cy="197.37"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="452.63"
              cy="197.37"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="429.14"
              cy="197.37"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="405.65"
              cy="197.37"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="382.16"
              cy="197.37"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="358.67"
              cy="197.37"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="335.18"
              cy="197.37"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="311.69"
              cy="197.37"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="288.2"
              cy="197.37"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="264.71"
              cy="197.37"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="241.22"
              cy="197.37"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="217.73"
              cy="197.37"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="194.24"
              cy="197.37"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="170.75"
              cy="197.37"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="147.26"
              cy="197.37"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="123.77"
              cy="197.37"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="100.28"
              cy="197.37"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="76.79"
              cy="197.37"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="53.3"
              cy="197.37"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="29.81"
              cy="197.37"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="6.32"
              cy="197.37"
              r="4.86"
            />
          </g>
          <g>
            <circle
              className={styles.dots__circle}
              cx="1180.7"
              cy="218.76"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1157.21"
              cy="218.76"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1133.72"
              cy="218.76"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1110.23"
              cy="218.76"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1086.74"
              cy="218.76"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1063.25"
              cy="218.76"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1039.76"
              cy="218.76"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1016.27"
              cy="218.76"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="992.78"
              cy="218.76"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="969.29"
              cy="218.76"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="945.8"
              cy="218.76"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="922.31"
              cy="218.76"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="898.82"
              cy="218.76"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="875.33"
              cy="218.76"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="851.84"
              cy="218.76"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="828.35"
              cy="218.76"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="804.86"
              cy="218.76"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="781.37"
              cy="218.76"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="757.88"
              cy="218.76"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="734.39"
              cy="218.76"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="710.9"
              cy="218.76"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="687.41"
              cy="218.76"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="663.92"
              cy="218.76"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="640.43"
              cy="218.76"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="616.94"
              cy="218.76"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="593.45"
              cy="218.76"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="569.96"
              cy="218.76"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="546.47"
              cy="218.76"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="522.98"
              cy="218.76"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="499.49"
              cy="218.76"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="476"
              cy="218.76"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="452.51"
              cy="218.76"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="429.02"
              cy="218.76"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="405.53"
              cy="218.76"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="382.04"
              cy="218.76"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="358.55"
              cy="218.76"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="335.06"
              cy="218.76"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="311.57"
              cy="218.76"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="288.08"
              cy="218.76"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="264.59"
              cy="218.76"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="241.1"
              cy="218.76"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="217.61"
              cy="218.76"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="194.12"
              cy="218.76"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="170.63"
              cy="218.76"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="147.14"
              cy="218.76"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="123.65"
              cy="218.76"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="100.16"
              cy="218.76"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="76.67"
              cy="218.76"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="53.18"
              cy="218.76"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="29.69"
              cy="218.76"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="6.2"
              cy="218.76"
              r="4.86"
            />
          </g>
          <g>
            <circle
              className={styles.dots__circle}
              cx="1180.57"
              cy="240.15"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1157.08"
              cy="240.15"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1133.59"
              cy="240.15"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1110.1"
              cy="240.15"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1086.61"
              cy="240.15"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1063.12"
              cy="240.15"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1039.63"
              cy="240.15"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1016.14"
              cy="240.15"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="992.65"
              cy="240.15"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="969.16"
              cy="240.15"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="945.67"
              cy="240.15"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="922.18"
              cy="240.15"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="898.69"
              cy="240.15"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="875.2"
              cy="240.15"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="851.71"
              cy="240.15"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="828.22"
              cy="240.15"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="804.73"
              cy="240.15"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="781.24"
              cy="240.15"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="757.75"
              cy="240.15"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="734.26"
              cy="240.15"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="710.77"
              cy="240.15"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="687.28"
              cy="240.15"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="663.79"
              cy="240.15"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="640.3"
              cy="240.15"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="616.81"
              cy="240.15"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="593.32"
              cy="240.15"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="569.83"
              cy="240.15"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="546.34"
              cy="240.15"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="522.85"
              cy="240.15"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="499.36"
              cy="240.15"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="475.87"
              cy="240.15"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="452.38"
              cy="240.15"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="428.89"
              cy="240.15"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="405.4"
              cy="240.15"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="381.91"
              cy="240.15"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="358.42"
              cy="240.15"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="334.93"
              cy="240.15"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="311.44"
              cy="240.15"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="287.95"
              cy="240.15"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="264.46"
              cy="240.15"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="240.97"
              cy="240.15"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="217.48"
              cy="240.15"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="193.99"
              cy="240.15"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="170.5"
              cy="240.15"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="147.01"
              cy="240.15"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="123.52"
              cy="240.15"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="100.03"
              cy="240.15"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="76.54"
              cy="240.15"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="53.05"
              cy="240.15"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="29.56"
              cy="240.15"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="6.07"
              cy="240.15"
              r="4.86"
            />
          </g>
          <g>
            <circle
              className={styles.dots__circle}
              cx="1180.45"
              cy="261.54"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1156.96"
              cy="261.54"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1133.47"
              cy="261.54"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1109.98"
              cy="261.54"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1086.49"
              cy="261.54"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1063"
              cy="261.54"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1039.51"
              cy="261.54"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1016.02"
              cy="261.54"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="992.53"
              cy="261.54"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="969.04"
              cy="261.54"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="945.55"
              cy="261.54"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="922.06"
              cy="261.54"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="898.57"
              cy="261.54"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="875.08"
              cy="261.54"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="851.59"
              cy="261.54"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="828.1"
              cy="261.54"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="804.61"
              cy="261.54"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="781.12"
              cy="261.54"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="757.63"
              cy="261.54"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="734.14"
              cy="261.54"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="710.65"
              cy="261.54"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="687.16"
              cy="261.54"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="663.67"
              cy="261.54"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="640.18"
              cy="261.54"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="616.69"
              cy="261.54"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="593.2"
              cy="261.54"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="569.71"
              cy="261.54"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="546.22"
              cy="261.54"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="522.73"
              cy="261.54"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="499.24"
              cy="261.54"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="475.75"
              cy="261.54"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="452.26"
              cy="261.54"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="428.77"
              cy="261.54"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="405.28"
              cy="261.54"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="381.79"
              cy="261.54"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="358.3"
              cy="261.54"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="334.81"
              cy="261.54"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="311.32"
              cy="261.54"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="287.83"
              cy="261.54"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="264.34"
              cy="261.54"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="240.85"
              cy="261.54"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="217.36"
              cy="261.54"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="193.87"
              cy="261.54"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="170.38"
              cy="261.54"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="146.89"
              cy="261.54"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="123.4"
              cy="261.54"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="99.91"
              cy="261.54"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="76.42"
              cy="261.54"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="52.93"
              cy="261.54"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="29.44"
              cy="261.54"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="5.95"
              cy="261.54"
              r="4.86"
            />
          </g>
          <g>
            <circle
              className={styles.dots__circle}
              cx="1180.33"
              cy="282.93"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1156.84"
              cy="282.93"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1133.35"
              cy="282.93"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1109.86"
              cy="282.93"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1086.37"
              cy="282.93"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1062.88"
              cy="282.93"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1039.39"
              cy="282.93"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1015.9"
              cy="282.93"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="992.41"
              cy="282.93"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="968.92"
              cy="282.93"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="945.43"
              cy="282.93"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="921.94"
              cy="282.93"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="898.45"
              cy="282.93"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="874.96"
              cy="282.93"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="851.47"
              cy="282.93"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="827.98"
              cy="282.93"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="804.49"
              cy="282.93"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="781"
              cy="282.93"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="757.51"
              cy="282.93"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="734.02"
              cy="282.93"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="710.53"
              cy="282.93"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="687.04"
              cy="282.93"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="663.55"
              cy="282.93"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="640.06"
              cy="282.93"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="616.57"
              cy="282.93"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="593.08"
              cy="282.93"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="569.59"
              cy="282.93"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="546.1"
              cy="282.93"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="522.61"
              cy="282.93"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="499.12"
              cy="282.93"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="475.63"
              cy="282.93"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="452.14"
              cy="282.93"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="428.65"
              cy="282.93"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="405.16"
              cy="282.93"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="381.67"
              cy="282.93"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="358.18"
              cy="282.93"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="334.69"
              cy="282.93"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="311.2"
              cy="282.93"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="287.71"
              cy="282.93"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="264.22"
              cy="282.93"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="240.73"
              cy="282.93"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="217.24"
              cy="282.93"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="193.75"
              cy="282.93"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="170.26"
              cy="282.93"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="146.77"
              cy="282.93"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="123.28"
              cy="282.93"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="99.79"
              cy="282.93"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="76.3"
              cy="282.93"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="52.81"
              cy="282.93"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="29.32"
              cy="282.93"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="5.83"
              cy="282.93"
              r="4.86"
            />
          </g>
          <g>
            <circle
              className={styles.dots__circle}
              cx="1180.21"
              cy="304.32"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1156.72"
              cy="304.32"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1133.23"
              cy="304.32"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1109.74"
              cy="304.32"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1086.25"
              cy="304.32"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1062.76"
              cy="304.32"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1039.27"
              cy="304.32"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1015.78"
              cy="304.32"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="992.29"
              cy="304.32"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="968.8"
              cy="304.32"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="945.31"
              cy="304.32"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="921.82"
              cy="304.32"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="898.33"
              cy="304.32"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="874.84"
              cy="304.32"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="851.35"
              cy="304.32"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="827.86"
              cy="304.32"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="804.37"
              cy="304.32"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="780.88"
              cy="304.32"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="757.39"
              cy="304.32"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="733.9"
              cy="304.32"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="710.41"
              cy="304.32"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="686.92"
              cy="304.32"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="663.43"
              cy="304.32"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="639.94"
              cy="304.32"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="616.45"
              cy="304.32"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="592.96"
              cy="304.32"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="569.47"
              cy="304.32"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="545.98"
              cy="304.32"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="522.49"
              cy="304.32"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="499"
              cy="304.32"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="475.51"
              cy="304.32"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="452.02"
              cy="304.32"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="428.53"
              cy="304.32"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="405.04"
              cy="304.32"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="381.55"
              cy="304.32"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="358.06"
              cy="304.32"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="334.57"
              cy="304.32"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="311.08"
              cy="304.32"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="287.59"
              cy="304.32"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="264.1"
              cy="304.32"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="240.61"
              cy="304.32"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="217.12"
              cy="304.32"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="193.63"
              cy="304.32"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="170.14"
              cy="304.32"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="146.65"
              cy="304.32"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="123.16"
              cy="304.32"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="99.67"
              cy="304.32"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="76.18"
              cy="304.32"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="52.69"
              cy="304.32"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="29.2"
              cy="304.32"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="5.71"
              cy="304.32"
              r="4.86"
            />
          </g>
          <g>
            <circle
              className={styles.dots__circle}
              cx="1180.09"
              cy="325.71"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1156.6"
              cy="325.71"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1133.11"
              cy="325.71"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1109.62"
              cy="325.71"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1086.13"
              cy="325.71"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1062.64"
              cy="325.71"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1039.15"
              cy="325.71"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1015.66"
              cy="325.71"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="992.17"
              cy="325.71"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="968.68"
              cy="325.71"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="945.19"
              cy="325.71"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="921.7"
              cy="325.71"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="898.21"
              cy="325.71"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="874.72"
              cy="325.71"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="851.23"
              cy="325.71"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="827.74"
              cy="325.71"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="804.25"
              cy="325.71"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="780.76"
              cy="325.71"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="757.27"
              cy="325.71"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="733.78"
              cy="325.71"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="710.29"
              cy="325.71"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="686.8"
              cy="325.71"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="663.31"
              cy="325.71"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="639.82"
              cy="325.71"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="616.33"
              cy="325.71"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="592.84"
              cy="325.71"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="569.35"
              cy="325.71"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="545.86"
              cy="325.71"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="522.37"
              cy="325.71"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="498.88"
              cy="325.71"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="475.39"
              cy="325.71"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="451.9"
              cy="325.71"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="428.41"
              cy="325.71"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="404.92"
              cy="325.71"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="381.43"
              cy="325.71"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="357.94"
              cy="325.71"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="334.45"
              cy="325.71"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="310.96"
              cy="325.71"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="287.47"
              cy="325.71"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="263.98"
              cy="325.71"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="240.49"
              cy="325.71"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="217"
              cy="325.71"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="193.51"
              cy="325.71"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="170.02"
              cy="325.71"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="146.53"
              cy="325.71"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="123.04"
              cy="325.71"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="99.55"
              cy="325.71"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="76.06"
              cy="325.71"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="52.57"
              cy="325.71"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="29.08"
              cy="325.71"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="5.59"
              cy="325.71"
              r="4.86"
            />
          </g>
          <g>
            <circle
              className={styles.dots__circle}
              cx="1179.97"
              cy="347.1"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1156.48"
              cy="347.1"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1132.99"
              cy="347.1"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1109.5"
              cy="347.1"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1086.01"
              cy="347.1"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1062.52"
              cy="347.1"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1039.03"
              cy="347.1"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1015.54"
              cy="347.1"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="992.05"
              cy="347.1"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="968.56"
              cy="347.1"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="945.07"
              cy="347.1"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="921.58"
              cy="347.1"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="898.09"
              cy="347.1"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="874.6"
              cy="347.1"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="851.11"
              cy="347.1"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="827.62"
              cy="347.1"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="804.13"
              cy="347.1"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="780.64"
              cy="347.1"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="757.15"
              cy="347.1"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="733.66"
              cy="347.1"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="710.17"
              cy="347.1"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="686.68"
              cy="347.1"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="663.19"
              cy="347.1"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="639.7"
              cy="347.1"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="616.21"
              cy="347.1"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="592.72"
              cy="347.1"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="569.23"
              cy="347.1"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="545.74"
              cy="347.1"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="522.25"
              cy="347.1"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="498.76"
              cy="347.1"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="475.27"
              cy="347.1"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="451.78"
              cy="347.1"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="428.29"
              cy="347.1"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="404.8"
              cy="347.1"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="381.31"
              cy="347.1"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="357.82"
              cy="347.1"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="334.33"
              cy="347.1"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="310.84"
              cy="347.1"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="287.35"
              cy="347.1"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="263.86"
              cy="347.1"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="240.37"
              cy="347.1"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="216.88"
              cy="347.1"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="193.39"
              cy="347.1"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="169.9"
              cy="347.1"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="146.41"
              cy="347.1"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="122.92"
              cy="347.1"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="99.43"
              cy="347.1"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="75.94"
              cy="347.1"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="52.45"
              cy="347.1"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="28.96"
              cy="347.1"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="5.47"
              cy="347.1"
              r="4.86"
            />
          </g>
          <g>
            <circle
              className={styles.dots__circle}
              cx="1179.85"
              cy="368.49"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1156.36"
              cy="368.49"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1132.87"
              cy="368.49"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1109.38"
              cy="368.49"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1085.89"
              cy="368.49"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1062.4"
              cy="368.49"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1038.91"
              cy="368.49"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1015.42"
              cy="368.49"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="991.93"
              cy="368.49"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="968.44"
              cy="368.49"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="944.95"
              cy="368.49"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="921.46"
              cy="368.49"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="897.97"
              cy="368.49"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="874.48"
              cy="368.49"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="850.99"
              cy="368.49"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="827.5"
              cy="368.49"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="804.01"
              cy="368.49"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="780.52"
              cy="368.49"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="757.03"
              cy="368.49"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="733.54"
              cy="368.49"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="710.05"
              cy="368.49"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="686.56"
              cy="368.49"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="663.07"
              cy="368.49"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="639.58"
              cy="368.49"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="616.09"
              cy="368.49"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="592.6"
              cy="368.49"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="569.11"
              cy="368.49"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="545.62"
              cy="368.49"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="522.13"
              cy="368.49"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="498.64"
              cy="368.49"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="475.15"
              cy="368.49"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="451.66"
              cy="368.49"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="428.17"
              cy="368.49"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="404.68"
              cy="368.49"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="381.19"
              cy="368.49"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="357.7"
              cy="368.49"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="334.21"
              cy="368.49"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="310.72"
              cy="368.49"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="287.23"
              cy="368.49"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="263.74"
              cy="368.49"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="240.25"
              cy="368.49"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="216.76"
              cy="368.49"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="193.27"
              cy="368.49"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="169.78"
              cy="368.49"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="146.29"
              cy="368.49"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="122.8"
              cy="368.49"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="99.31"
              cy="368.49"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="75.82"
              cy="368.49"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="52.33"
              cy="368.49"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="28.84"
              cy="368.49"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="5.35"
              cy="368.49"
              r="4.86"
            />
          </g>
          <g>
            <circle
              className={styles.dots__circle}
              cx="1179.72"
              cy="389.88"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1156.23"
              cy="389.88"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1132.74"
              cy="389.88"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1109.25"
              cy="389.88"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1085.76"
              cy="389.88"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1062.27"
              cy="389.88"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1038.78"
              cy="389.88"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1015.29"
              cy="389.88"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="991.8"
              cy="389.88"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="968.31"
              cy="389.88"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="944.82"
              cy="389.88"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="921.33"
              cy="389.88"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="897.84"
              cy="389.88"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="874.35"
              cy="389.88"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="850.86"
              cy="389.88"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="827.37"
              cy="389.88"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="803.88"
              cy="389.88"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="780.39"
              cy="389.88"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="756.9"
              cy="389.88"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="733.41"
              cy="389.88"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="709.92"
              cy="389.88"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="686.43"
              cy="389.88"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="662.94"
              cy="389.88"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="639.45"
              cy="389.88"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="615.96"
              cy="389.88"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="592.47"
              cy="389.88"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="568.98"
              cy="389.88"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="545.49"
              cy="389.88"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="522"
              cy="389.88"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="498.51"
              cy="389.88"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="475.02"
              cy="389.88"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="451.53"
              cy="389.88"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="428.04"
              cy="389.88"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="404.55"
              cy="389.88"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="381.06"
              cy="389.88"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="357.57"
              cy="389.88"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="334.08"
              cy="389.88"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="310.59"
              cy="389.88"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="287.1"
              cy="389.88"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="263.61"
              cy="389.88"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="240.12"
              cy="389.88"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="216.63"
              cy="389.88"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="193.14"
              cy="389.88"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="169.65"
              cy="389.88"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="146.16"
              cy="389.88"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="122.67"
              cy="389.88"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="99.18"
              cy="389.88"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="75.69"
              cy="389.88"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="52.2"
              cy="389.88"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="28.71"
              cy="389.88"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="5.22"
              cy="389.88"
              r="4.86"
            />
          </g>
          <g>
            <circle
              className={styles.dots__circle}
              cx="1179.6"
              cy="411.27"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1156.11"
              cy="411.27"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1132.62"
              cy="411.27"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1109.13"
              cy="411.27"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1085.64"
              cy="411.27"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1062.15"
              cy="411.27"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1038.66"
              cy="411.27"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1015.17"
              cy="411.27"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="991.68"
              cy="411.27"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="968.19"
              cy="411.27"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="944.7"
              cy="411.27"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="921.21"
              cy="411.27"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="897.72"
              cy="411.27"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="874.23"
              cy="411.27"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="850.74"
              cy="411.27"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="827.25"
              cy="411.27"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="803.76"
              cy="411.27"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="780.27"
              cy="411.27"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="756.78"
              cy="411.27"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="733.29"
              cy="411.27"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="709.8"
              cy="411.27"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="686.31"
              cy="411.27"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="662.82"
              cy="411.27"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="639.33"
              cy="411.27"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="615.84"
              cy="411.27"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="592.35"
              cy="411.27"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="568.86"
              cy="411.27"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="545.37"
              cy="411.27"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="521.88"
              cy="411.27"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="498.39"
              cy="411.27"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="474.9"
              cy="411.27"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="451.41"
              cy="411.27"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="427.92"
              cy="411.27"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="404.43"
              cy="411.27"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="380.94"
              cy="411.27"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="357.45"
              cy="411.27"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="333.96"
              cy="411.27"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="310.47"
              cy="411.27"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="286.98"
              cy="411.27"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="263.49"
              cy="411.27"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="240"
              cy="411.27"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="216.51"
              cy="411.27"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="193.02"
              cy="411.27"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="169.53"
              cy="411.27"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="146.04"
              cy="411.27"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="122.55"
              cy="411.27"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="99.06"
              cy="411.27"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="75.57"
              cy="411.27"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="52.08"
              cy="411.27"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="28.59"
              cy="411.27"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="5.1"
              cy="411.27"
              r="4.86"
            />
          </g>
          <g>
            <circle
              className={styles.dots__circle}
              cx="1179.48"
              cy="432.66"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1155.99"
              cy="432.66"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1132.5"
              cy="432.66"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1109.01"
              cy="432.66"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1085.52"
              cy="432.66"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1062.03"
              cy="432.66"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1038.54"
              cy="432.66"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1015.05"
              cy="432.66"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="991.56"
              cy="432.66"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="968.07"
              cy="432.66"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="944.58"
              cy="432.66"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="921.09"
              cy="432.66"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="897.6"
              cy="432.66"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="874.11"
              cy="432.66"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="850.62"
              cy="432.66"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="827.13"
              cy="432.66"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="803.64"
              cy="432.66"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="780.15"
              cy="432.66"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="756.66"
              cy="432.66"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="733.17"
              cy="432.66"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="709.68"
              cy="432.66"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="686.19"
              cy="432.66"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="662.7"
              cy="432.66"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="639.21"
              cy="432.66"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="615.72"
              cy="432.66"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="592.23"
              cy="432.66"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="568.74"
              cy="432.66"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="545.25"
              cy="432.66"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="521.76"
              cy="432.66"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="498.27"
              cy="432.66"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="474.78"
              cy="432.66"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="451.29"
              cy="432.66"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="427.8"
              cy="432.66"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="404.31"
              cy="432.66"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="380.82"
              cy="432.66"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="357.33"
              cy="432.66"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="333.84"
              cy="432.66"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="310.35"
              cy="432.66"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="286.86"
              cy="432.66"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="263.37"
              cy="432.66"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="239.88"
              cy="432.66"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="216.39"
              cy="432.66"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="192.9"
              cy="432.66"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="169.41"
              cy="432.66"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="145.92"
              cy="432.66"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="122.43"
              cy="432.66"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="98.94"
              cy="432.66"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="75.45"
              cy="432.66"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="51.96"
              cy="432.66"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="28.47"
              cy="432.66"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="4.98"
              cy="432.66"
              r="4.86"
            />
          </g>
          <g>
            <circle
              className={styles.dots__circle}
              cx="1179.36"
              cy="454.05"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1155.87"
              cy="454.05"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1132.38"
              cy="454.05"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1108.89"
              cy="454.05"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1085.4"
              cy="454.05"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1061.91"
              cy="454.05"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1038.42"
              cy="454.05"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="1014.93"
              cy="454.05"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="991.44"
              cy="454.05"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="967.95"
              cy="454.05"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="944.46"
              cy="454.05"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="920.97"
              cy="454.05"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="897.48"
              cy="454.05"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="873.99"
              cy="454.05"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="850.5"
              cy="454.05"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="827.01"
              cy="454.05"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="803.52"
              cy="454.05"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="780.03"
              cy="454.05"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="756.54"
              cy="454.05"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="733.05"
              cy="454.05"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="709.56"
              cy="454.05"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="686.07"
              cy="454.05"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="662.58"
              cy="454.05"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="639.09"
              cy="454.05"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="615.6"
              cy="454.05"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="592.11"
              cy="454.05"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="568.62"
              cy="454.05"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="545.13"
              cy="454.05"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="521.64"
              cy="454.05"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="498.15"
              cy="454.05"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="474.66"
              cy="454.05"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="451.17"
              cy="454.05"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="427.68"
              cy="454.05"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="404.19"
              cy="454.05"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="380.7"
              cy="454.05"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="357.21"
              cy="454.05"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="333.72"
              cy="454.05"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="310.23"
              cy="454.05"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="286.74"
              cy="454.05"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="263.25"
              cy="454.05"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="239.76"
              cy="454.05"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="216.27"
              cy="454.05"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="192.78"
              cy="454.05"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="169.29"
              cy="454.05"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="145.8"
              cy="454.05"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="122.31"
              cy="454.05"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="98.82"
              cy="454.05"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="75.33"
              cy="454.05"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="51.84"
              cy="454.05"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="28.35"
              cy="454.05"
              r="4.86"
            />
            <circle
              className={styles.dots__circle}
              cx="4.86"
              cy="454.05"
              r="4.86"
            />
          </g>
        </g>
      </svg> */}
    </div>
  )
}

export default DotsAnimation
