import { useEffect, useRef, useState } from 'react'
import { gsap } from 'gsap'
import {
  storyblokEditable,
  SbBlokData,
  type ISbRichtext,
} from '@storyblok/react/rsc'
import styles from './Hero.module.scss'
import clsx from 'clsx'
import Text from '@/components/storyblok/Text'
import LottieAnimation from '@/components/LottieAnimation'

interface HeroProps {
  blok: SbBlokData & {
    internalName: string
    heading: ISbRichtext
    eyebrowLong: string
    eyebrowShort: string
    displayAnimation: boolean
  }
}

const Hero = ({ blok }: HeroProps) => {
  const [animation, setAnimation] = useState(false)
  const heroRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    const ctx = gsap.context(() => {
      const tl = gsap.timeline()
      tl.fromTo(
        '#heading',
        {
          opacity: 0,
        },
        {
          opacity: 1,
          duration: 1,
          ease: 'power1.inOut',
        }
      )

      tl.from('#eyebrow-long', {
        y: -2000,
        duration: 0.3,
        ease: 'power1.inOut',
      })

      tl.from('#eyebrow-short', {
        x: 3000,
        duration: 0.5,
        delay: 0.1,
        ease: 'power1.inOut',
      })
    }, heroRef)
    return () => ctx.revert()
  }, [])

  useEffect(() => {
    const timer = setTimeout(() => {
      setAnimation(true)
    }, 2500)

    return () => clearTimeout(timer) // Cleanup the timer on component unmount
  }, [])

  return (
    <div
      {...storyblokEditable(blok)}
      className={clsx(
        styles.hero__wrapper,
        blok.displayAnimation
          ? styles.hero__wrapper__animation
          : styles.hero__wrapper__slimline
      )}
      ref={heroRef}
    >
      <div className={styles.hero__container}>
        <p
          id="eyebrow-long"
          className={clsx(styles.hero__eyebrow, styles.hero__eyebrow__long)}
        >
          {blok.eyebrowLong}
        </p>
        <div id="heading" className={styles.hero__heading}>
          <Text
            wrapperClass={styles.hero__heading}
            blok={{ text: blok.heading }}
          />
        </div>
        <span
          id="eyebrow-short"
          className={clsx(styles.hero__eyebrow, styles.hero__eyebrow__short)}
        >
          {blok.eyebrowShort}
        </span>
      </div>
      {animation && (
        <div className={styles.hero__animation}>
          <LottieAnimation
            loop
            autoplay
            animationData="/animations/hero-l.json"
          />
        </div>
      )}
    </div>
  )
}

export default Hero
