import React from 'react'

const TeamIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0.00 0.00 64.00 64.00"
  >
    <path
      fill="currentColor"
      d=" M 51.15 32.65C 60.92 38.73 62.22 46.85 61.82 57.76A 2.06 2.06 0.0 0 1 59.76 59.74L 4.23 59.74A 2.00 1.99 -2.2 0 1 2.24 57.90C 1.43 47.39 3.25 38.39 12.85 32.72Q 13.34 32.44 13.00 31.99C 12.05 30.71 10.58 29.32 10.04 27.90Q 5.87 16.92 13.60 10.22C 18.82 5.69 25.41 5.23 31.33 8.67A 0.98 0.96 -41.8 0 0 32.22 8.71Q 44.79 2.95 51.95 11.87C 57.02 18.19 56.53 26.03 51.05 31.89Q 50.64 32.33 51.15 32.65ZM 34.09 21.33A 10.64 10.64 0.0 0 0 23.45 10.69A 10.64 10.64 0.0 0 0 12.81 21.33A 10.64 10.64 0.0 0 0 23.45 31.97A 10.64 10.64 0.0 0 0 34.09 21.33ZM 35.54 12.62C 39.68 18.63 38.91 24.05 35.54 30.13Q 35.29 30.58 35.76 30.79C 46.47 35.69 56.25 23.04 48.45 14.21C 45.45 10.82 39.84 9.49 35.70 11.90Q 35.22 12.17 35.54 12.62ZM 17.49 35.26C 9.12 38.48 5.40 46.41 6.56 55.07Q 6.62 55.48 7.04 55.48L 39.75 55.48Q 40.29 55.48 40.36 54.95Q 41.87 43.07 32.30 36.46Q 29.95 34.85 26.53 35.94Q 23.20 37.01 18.40 35.26A 1.32 1.28 44.2 0 0 17.49 35.26ZM 57.42 54.90C 58.50 46.35 54.88 38.53 46.67 35.29Q 46.19 35.10 45.71 35.29Q 42.62 36.48 39.31 36.17Q 38.65 36.12 39.09 36.61Q 45.85 44.40 44.66 54.80Q 44.58 55.48 45.27 55.48L 56.77 55.48Q 57.35 55.48 57.42 54.90Z"
    />
  </svg>
)

export default TeamIcon
