import React from 'react'

const ContactsIcon = () => (
  <svg viewBox="0 0 792 746" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g transform="matrix(8.33333,0,0,8.33333,692.278,282.444)">
      <path
        d="M0,22.382C-0.002,22.381 -0.004,22.381 -0.007,22.38C-0.022,22.483 -0.021,22.586 -0.042,22.688C0.173,21.608 -0.088,23.445 -0.107,23.672C-0.187,24.635 -0.448,26.579 -0.448,26.579L-0.799,29.24C-0.799,29.24 -1.365,33.247 -1.621,35.489C-10.478,32.074 -18.958,28.804 -27.366,25.562L-25.487,21.807L-23.127,17.629L-20.595,14.146C-12.391,2.85 -4.431,-8.111 3.524,-19.066C3.886,-18.878 4.248,-18.69 4.61,-18.502C3.093,-5.045 1.575,8.412 0,22.382M-33.674,43.528C-32.748,39.099 -32.15,36.131 -31.494,33.176C-31.334,32.454 -30.986,31.774 -30.683,30.963C-28.05,31.169 -25.891,32.228 -23.619,33.606C-26.593,36.951 -29.568,40.069 -33.674,43.528M-37.471,33.476C-37.556,35.333 -38.281,37.16 -39.016,40.298C-42.147,33.916 -43.514,28.144 -45.512,22.044C-34.902,12.373 -24.342,2.747 -13.782,-6.879C-13.529,-6.6 -13.276,-6.321 -13.023,-6.043C-18.772,1.845 -24.437,9.795 -30.3,17.597C-33.898,22.385 -37.182,27.189 -37.471,33.476M-44.496,13.073C-46.649,15.098 -48.028,16.395 -49.654,17.925C-56.823,15.524 -63.993,13.121 -71.717,10.533L-64.756,7.163L-58.667,4.01L-53.233,1.036C-36.997,-6.597 -25.056,-12.211 -9.778,-19.394L-9.359,-18.678C-19.24,-9.678 -29.121,-0.678 -39.393,8.677M5.148,-32.797C-22.93,-19.568 -51.022,-6.369 -79.091,6.879C-80.899,7.733 -83.391,8.228 -83.04,11.088C-82.738,13.543 -80.44,13.7 -78.724,14.287C-71.119,16.889 -63.523,19.539 -55.819,21.816C-52.27,22.864 -50.567,24.872 -49.536,28.321C-47.288,35.839 -44.615,43.229 -42.163,50.687C-40.208,56.635 -38.648,57.011 -34.204,52.568C-28.532,46.899 -22.836,41.255 -17.5,35.948C-10.179,37.472 -4.43,41.801 3.552,42.401C6.394,16.867 9.175,-8.117 11.957,-33.101C8.565,-34.629 6.894,-33.62 5.148,-32.797"
        fill="currentColor"
      />
    </g>
  </svg>
)

export default ContactsIcon
