import React from 'react'
import NextImage from 'next/image'
import { type SbBlokData, storyblokEditable } from '@storyblok/react'

import styles from './Image.module.scss'
import LottieAnimation from '@/components/LottieAnimation'

interface ImageProps {
  blok: SbBlokData & {
    image: {
      alt: string
      name: string
      filename: string
    }
  }
}

const Image = ({ blok }: ImageProps) => {
  const [width, height] = blok.image.filename?.split('/')[5]?.split('x') ?? []
  return (
    <div className={styles.image__wrapper} {...storyblokEditable(blok)}>
      <NextImage
        className={styles.image__image}
        alt={blok.image.alt ? blok.image.alt : blok.image.name}
        src={`${blok.image.filename}/m/smart/filters:format(avif)`}
        width={Number(width) ?? 200}
        height={Number(height) ?? 200}
        {...storyblokEditable(blok)}
      />
      <div className={styles.image__animation}>
        <LottieAnimation
          loop
          autoplay
          animationData="/animations/image-background-animation.json"
        />
      </div>
    </div>
  )
}

export default Image
