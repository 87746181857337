import React from 'react'
import { ISbRichtext, SbBlokData, storyblokEditable } from '@storyblok/react'
import { Navigation } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import styles from './ReviewSlider.module.scss'
import { render } from 'storyblok-rich-text-react-renderer'

export interface ReviwewCardProps {
  heading: string
  text: ISbRichtext
  organisation: string
  author: string
  position: string
  _uid: string
}

interface ReviewSliderProps {
  blok: SbBlokData & {
    slides: SbBlokData & ReviwewCardProps[]
  }
}

const ReviewSlider = ({ blok }: ReviewSliderProps) => {
  return (
    <div {...storyblokEditable(blok)}>
      <div className={styles.reviewSlider__swiper__section}>
        <div className={styles.reviewSlider__swiper__container}>
          <Swiper
            {...storyblokEditable(blok)}
            className={styles.reviewSlider__wrapper}
            spaceBetween={4}
            slidesPerView={1}
            loop={true}
            modules={[Navigation]}
            navigation={{
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            }}
            breakpoints={{
              768: {
                slidesPerView: 3,
              },
            }}
          >
            {Array.isArray(blok?.slides) &&
              blok?.slides?.map((entry, index) => (
                <SwiperSlide
                  className={styles.reviewSlider__container}
                  key={entry._uid}
                  virtualIndex={index}
                >
                  <div className={styles.reviewSlider__content__wrapper}>
                    <div className={styles.reviewSlider__content}>
                      {entry.heading}
                      {render(entry.text)}
                    </div>
                    <div className={styles.reviewSlider__author}>
                      <div>{entry.organisation}</div>
                      <div>{entry.author}</div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
          </Swiper>
          <div className="swiper-button-prev" />
          <div className="swiper-button-next" />
        </div>
      </div>
    </div>
  )
}

export default ReviewSlider
