import React, { useState } from 'react'
import { SbBlokData, storyblokEditable } from '@storyblok/react'
import dynamic from 'next/dynamic'

const ReactPlayer = dynamic(() => import('react-player'), { ssr: false })

import styles from './Video.module.scss'

interface VideoProps {
  blok: SbBlokData & {
    autoplay?: boolean
    hideControl?: boolean
    vimeoLink: {
      url: string
    }
  }
}

const Video = ({ blok }: VideoProps) => {
  const autoplay = Boolean(blok.autoplay)
  const [playing, setPlaying] = useState(autoplay)
  return (
    <div className={styles.video__wrapper}>
      <ReactPlayer
        {...storyblokEditable(blok)}
        className={styles.video__player}
        url={blok.vimeoLink.url}
        playing={playing}
        controls={blok.hideControl ? false : true}
        loop={autoplay}
        muted={autoplay}
        onPause={() => {
          setPlaying(false)
        }}
        onPlay={() => {
          setPlaying(true)
        }}
        config={{
          youtube: {
            playerVars: {
              showinfo: 1,
            },
          },
        }}
      />
    </div>
  )
}

export default Video
