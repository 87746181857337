import {
  storyblokEditable,
  StoryblokComponent,
  SbBlokData,
} from '@storyblok/react/rsc'
import clsx from 'clsx'
import styles from './Duplex.module.scss'

interface DuplexProps {
  blok: SbBlokData & {
    internalName: string
    columnOne: SbBlokData[]
    hideColumnOneMobile: boolean
    columnTwo: SbBlokData[]
    hideColumnTwoMobile: boolean
  }
}

const Duplex = ({ blok }: DuplexProps) => (
  <div className={styles.duplex__wrapper}>
    <div {...storyblokEditable(blok)} className={clsx(
      styles.duplex__column,
      blok.hideColumnOneMobile && styles.duplex__column__hidden__mobile
    )}>
      {blok.columnOne.map((nestedBlok) => (
        <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
      ))}
    </div>
    <div {...storyblokEditable(blok)} className={clsx(
      styles.duplex__column,
      blok.hideColumnTwoMobile && styles.duplex__column__hidden__mobile
    )}>
      {blok.columnTwo.map((nestedBlok) => (
        <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
      ))}
    </div>
  </div>
)

export default Duplex
