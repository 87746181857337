import {
  storyblokEditable,
  StoryblokComponent,
  SbBlokData,
} from '@storyblok/react/rsc'
import Marquee from 'react-fast-marquee'
import styles from './SliderMarquee.module.scss'

interface SliderMarqueeProps {
  blok: SbBlokData & {
    internalName: string
    direction: 'left' | 'right'
    slides: SbBlokData[]
  }
}

const SliderMarquee = ({ blok }: SliderMarqueeProps) => (
  <Marquee
    {...storyblokEditable(blok)}
    direction={blok.direction ?? 'left'}
    pauseOnHover
    autoFill
    speed={7}
    className={styles.sliderMarquee}
  >
    {blok.slides.map((nestedBlok) => (
      <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
    ))}
  </Marquee>
)

export default SliderMarquee
