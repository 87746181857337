import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation } from "@tanstack/react-query";
import * as yup from 'yup'
import clsx from 'clsx'
import Button from '@/components/storyblok/Button'
import ArrowRight from '@/assets/icons/arrow-right.svg'

import styles from './FormContact.module.scss'
import LottieAnimation from '@/components/LottieAnimation'

const schema = yup
  .object({
    name: yup.string().required('Name cannot be empty'),
    phone: yup
      .string()
      .required('Phone number cannot be empty')
      .min(6, 'Please ensure you have enterd a valid phone number'),
    email: yup
      .string()
      .email('Please ensure you have entered a valid email address')
      .required('Email cannot be empty'),
    company: yup.string(),
    message: yup.string(),
  })
  .required()

type FormData = {
  name: string
  phone: string
  email: string
  company?: string
  message?: string
}

type ControlData = {
  color: string
  border: string
}

const FormContact = () => {
  const { register, handleSubmit, formState: { errors }, reset } = useForm<FormData>({
    resolver: yupResolver(schema),
  })

  const sendForm = async (data: FormData) => {
    const res = await fetch("https://formspree.io/f/xanwzrrw", {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json'
      },
    });
    return res.json();
  }

  const [verify, setVerify] = useState(false);

  const mutation = useMutation({
    mutationFn: sendForm,
    onSuccess() {
      reset();
      setVerify(true);
    },
  });

  const onSubmit = (data: FormData) => mutation.mutate(data);

  const [controls, setControls] = useState<ControlData>({
    color: '#c704d1',
    border: 'style3',
  })

  const colors = [
    { name: 'Ambitious', hex: '#c704d1' },
    { name: 'Nostalgic', hex: '#cc7d3b' },
    { name: 'Inspried', hex: '#4c26ff' },
  ]

  const border = [
    { name: '50% Fun Mode', value: 'style1' },
    { name: '75% Fun Mode', value: 'style2' },
    { name: '100% Fun Mode', value: 'style3' },
  ]

  const handleChange = (event: React.ChangeEvent) => {
    const { name, value } = event.target as HTMLButtonElement
    setControls({ ...controls, [name]: value })
  }

  return (
    <div className={styles.form__container}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div
          style={{
            ['--fill-color' as any]: `${controls.color}`,
          }}
          className={clsx(
            styles.form__wrapper,
            styles[`form__wrapper__style__${controls.border}`]
          )}
        >
          {controls.border === 'style1' && (
            <svg height="100%" width="100%" xmlns="http://www.w3.org/2000/svg">
              <rect
                rx="15"
                ry="15"
                height="100%"
                width="100%"
                strokeLinejoin="round"
              />
            </svg>
          )}
          <div
            className={clsx(
              styles.form__wrapper__inner,
              styles[`form__wrapper__inner__${controls.border}`]
            )}
          >
            <div className={styles.form__header}>
              <LottieAnimation
                loop
                autoplay
                animationData="/animations/email-animation.json"
              />
            </div>
            {verify ? (
              <div className={styles.form__verify} style={{['--fill-color' as any]: `${controls.color}`}}>
                <p>Thank you for getting in touch! We will get back to you shortly.</p>
              </div>
            ): (
            <>
              <div className={styles.form__content}>
                <div className={styles.form__body}>
                  <div className={styles.form__input}>
                    <input
                      type="name"
                      id="name"
                      placeholder=" "
                      {...register('name')}
                    />
                    <label htmlFor="name">Name</label>
                  </div>
                  {
                    errors?.name && 
                    <span className={styles.form__input__error}  
                    style={{['--fill-color' as any]: `${controls.color}`}}>{errors?.name.message}</span>
                  }
                  <div className={styles.form__input}>
                    <input
                      type="text"
                      id="company"
                      placeholder=" "
                      {...register('company')}
                    />
                    <label htmlFor="company">Company</label>
                  </div>
                  <div className={styles.form__input}>
                    <input
                      type="phone"
                      id="phone"
                      placeholder=" "
                      {...register('phone')}
                    />
                    <label htmlFor="phone">Mobile</label>
                  </div>
                  {
                    errors?.phone && <span className={styles.form__input__error}
                    style={{['--fill-color' as any]: `${controls.color}`}}>
                      {errors?.phone.message}
                    </span>
                  }
                  <div className={styles.form__input}>
                    <input
                      type="email"
                      id="email"
                      placeholder=" "
                      {...register('email')}
                    />
                    <label htmlFor="email">Email</label>
                  </div>
                  {
                    errors?.email && <span className={styles.form__input__error}  
                    style={{['--fill-color' as any]: `${controls.color}`}}>{errors?.email.message}</span>
                  }
                </div>
                <div className={styles.form__body}>
                  <div
                    className={clsx(styles.form__input, styles.form__input__grow)}
                  >
                    <textarea
                      id="message"
                      placeholder=" "
                      {...register('message')}
                    />
                    <label htmlFor="message">How can we help?</label>
                  </div>
                </div>
              </div>
              <div className={styles.form__submit}>
                <Button
                  type="submit"
                  className={styles.form__submit}
                  align="right"
                  btnColor={controls.color}
                >
                  Get in touch
                  <ArrowRight />
                </Button>
              </div>
            </>
            )}
          </div>
        </div>
      </form>
      <div className={styles.form__controls}>
        <div className={styles.form__controls__border}>
          <select
            name="border"
            id="border"
            value={controls.border}
            onChange={handleChange}
            aria-label='Style'
            style={{
              ['--fill-color' as any]: `${controls.color}`,
            }}
          >
            {border.map((type) => (
              <option key={type.value} value={type.value}>
                {type.name}
              </option>
            ))}
          </select>
        </div>
        <div className={styles.form__controls__colors}>
          {colors.map((color) => (
            <button
              key={color.hex}
              type="button"
              className={clsx(
                styles.form__controls__color,
                controls.color === color.hex &&
                styles[`form__controls__color--active`]
              )}
              aria-label={`Colour ${color.hex}`}
              style={{ ['--fill-color' as any]: `${color.hex}` }}
              onClick={() => setControls({ ...controls, ['color']: color.hex })}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default FormContact
