import { storyblokEditable, type SbBlokData } from '@storyblok/react/rsc'
import Image from 'next/image'
import styles from './SlideImageText.module.scss'

interface SlideImageTextProps {
  blok: SbBlokData & {
    internalName: string
    text: string
    image: {
      filename: string
      alt: string
    }[]
  }
}

const SlideImageText = ({ blok }: SlideImageTextProps) => (
  <div {...storyblokEditable(blok)} className={styles.slide}>
    <Image
      src={blok.image[0]?.filename}
      alt={blok.image[0]?.alt ?? blok.text}
      width={40}
      height={40}
      className={styles.slide__image}
    />
  </div>
)

export default SlideImageText
