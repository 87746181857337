import React from 'react'
import { DotLottieReact } from '@lottiefiles/dotlottie-react'

interface AnnimationProps {
  loop: boolean
  autoplay: boolean
  animationData: string
}

const LottieAnimation = ({
  loop,
  autoplay,
  animationData,
}: AnnimationProps) => (
  <DotLottieReact src={animationData} loop={loop} autoplay={autoplay} speed={0.6} />
)

export default LottieAnimation
