import type { PropsWithChildren } from 'react'
import { storyblokInit, apiPlugin } from '@storyblok/react/rsc'
import CaseStudy from '@/components/storyblok/CaseStudy'
import Page from '@/components/storyblok/Page'
import Section from '@/components/storyblok/Section'
import Hero from '@/components/storyblok/Hero'
import ReviewSlider from '@/components/storyblok/ReviewSlider'
import ReviewCard from '@/components/storyblok/ReviewCard'
import SliderMarquee from '@/components/storyblok/SliderMarquee'
import SliderPortfolio from '@/components/storyblok/SliderPortfolio'
import SlideImageText from '@/components/storyblok/SlideImageText'
import Text from '@/components/storyblok/Text'
import Header from '@/components/storyblok/Header'
import Button from '@/components/storyblok/Button'
import FormContact from '@/components/storyblok/FormContact'
import Faq from '@/components/storyblok/Faq'
import Blurbicon from '@/components/storyblok/Blurbicon'
import Card from '@/components/storyblok/Card'
import CardGroup from '@/components/storyblok/CardGroup'
import Employee from '@/components/storyblok/Employee'
import EmployeeGroup from '@/components/storyblok/EmployeeGroup'
import SliderCoverflow from '@/components/storyblok/SliderCoverflow'
import Duplex from '@/components/storyblok/Duplex'
import Image from '@/components/storyblok/Image'
import Video from '@/components/storyblok/Video'

storyblokInit({
  accessToken: process.env.NEXT_PUBLIC_STORYBLOK_ACCESS_TOKEN,
  bridge: true,
  apiOptions: {
    region: 'us',
    timeout: 60,
  },
  use: [apiPlugin],
  components: {
    blurbicon: Blurbicon,
    button: Button,
    card: Card,
    caseStudy: CaseStudy,
    cardGroup: CardGroup,
    duplex: Duplex,
    employee: Employee,
    employeeGroup: EmployeeGroup,
    faq: Faq,
    formContact: FormContact,
    header: Header,
    hero: Hero,
    image: Image,
    page: Page,
    reviewCard: ReviewCard,
    reviewSlider: ReviewSlider,
    section: Section,
    slideImageText: SlideImageText,
    sliderCoverflow: SliderCoverflow,
    sliderPortfolio: SliderPortfolio,
    sliderMarquee: SliderMarquee,
    text: Text,
    video: Video,
  },
})

export default function StoryblokProvider({ children }: PropsWithChildren) {
  return children
}
