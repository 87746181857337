import React from 'react'
import Image from 'next/image'
import { SbBlokData, storyblokEditable } from '@storyblok/react'
import clsx from 'clsx'
import styles from './Header.module.scss'

interface HeaderProps {
  blok: SbBlokData & {
    image: {
      alt: string
      filename: string
    }
    heading: string
    size: 'h1' | 'h2' | 'h3' | 'h4'
  }
}

const Header = ({ blok }: HeaderProps) => {
  const HeaderComponent = blok.size ? blok.size : 'h1'
  return (
    <div className={styles.header__wrapper}>
      <HeaderComponent {...storyblokEditable(blok)} className={clsx(styles[`header__${blok.size}`])}>
      {blok.heading}
      </HeaderComponent>
      {blok.image?.filename && (
        <Image
          className={styles.header__image}
          priority={true}
          alt={blok.image.alt}
          src={blok.image.filename}
          width={30}
          height={30}
        />
      )}
    </div>
  )
}

export default Header
