import React, { useEffect, useRef, useState } from 'react'
import { SbBlokData, storyblokEditable } from '@storyblok/react'
import { contentInView } from '@/utils/contentInView'
import Employee, { EmployeeProps } from './Employee'

import styles from './EmployeeGroup.module.scss'
import clsx from 'clsx'

interface EmployeeGroupProps {
  blok: SbBlokData & {
    employees: Array<EmployeeProps['blok']>
  }
}

const EmployeeGroup = ({ blok }: EmployeeGroupProps) => {
  const divRef = useRef<HTMLDivElement>(null)
  const [isIntersecting, setIsIntersecting] = useState(false)
  useEffect(() => {
    const handleIntersection: IntersectionObserverCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsIntersecting(true)
        } else {
          setIsIntersecting(false)
        }
      })
    }

    if (divRef.current) {
      const observer = contentInView(divRef.current, handleIntersection)
      return () => observer.disconnect()
    }
  }, [])
  return (
    <div
      {...storyblokEditable(blok)}
      className={clsx(
        styles.employeegroup__container,
        isIntersecting && styles.employeegroup__container__inview
      )}
      ref={divRef}
    >
      {blok?.employees?.map((employee) => (
        <Employee key={employee._uid} blok={employee} />
      ))}
    </div>
  )
}

export default EmployeeGroup
