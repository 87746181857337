import React from 'react'
import Image from 'next/image'
import { render } from 'storyblok-rich-text-react-renderer'
import { ISbRichtext, SbBlokData, storyblokEditable } from '@storyblok/react'

import styles from './Blurbicon.module.scss'

interface BlurbiconProps {
  blok: SbBlokData & {
    image: {
      alt: string
      filename: string
    }
    heading: string
    text: ISbRichtext
  }
}

const Blurbicon = ({ blok }: BlurbiconProps) => {
  return (
    <div {...storyblokEditable(blok)} className={styles.blurbicon}>
      {blok.image?.filename && (
        <Image
          className={styles.blurbicon__image}
          alt={blok.image.alt}
          src={blok.image.filename}
          width={32}
          height={32}
        />
      )}
      <div>
        <span className={styles.blurbicon__heading}>{blok.heading}</span>
        <div className={styles.blurbicon__text}>{render(blok.text)}</div>
      </div>
    </div>
  )
}

export default Blurbicon
