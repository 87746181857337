import LaravelIcon from '@/assets/icons/logo-laravel.svg'
import LogoContentful from '@/assets/icons/logo-contentful.svg'
import LogoGraphQL from '@/assets/icons/logo-graphql.svg'
import LogoMQQT from '@/assets/icons/logo-mqqt.svg'
import LogoNext from '@/assets/icons/logo-next.svg'
import LogoNuxt from '@/assets/icons/logo-nuxt.svg'
import LogoStoryblok from '@/assets/icons/logo-storyblok.svg'
import LogoTypescript from '@/assets/icons/logo-typescript.svg'

const techIcons = {
  contentful: LogoContentful,
  graphql: LogoGraphQL,
  laravel: LaravelIcon,
  mqqt: LogoMQQT,
  next: LogoNext,
  nuxt: LogoNuxt,
  storyblok: LogoStoryblok,
  typescript: LogoTypescript,
}

const TechItem = ({
  tech,
}: {
  tech:
    | 'contentful'
    | 'graphql'
    | 'laravel'
    | 'mqqt'
    | 'next'
    | 'nuxt'
    | 'storyblok'
    | 'typescript'
}) => {
  const Icon = techIcons[tech]
  return (
    <Icon
      width={100} // @TODO: this should be coming from Storyblok
      height={90} // @TODO: this should be coming from Storyblok
      aria-label={`${tech}`}
    />
  )
}

export default TechItem
