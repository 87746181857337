import React from 'react'
import clsx from 'clsx'
import { ISbRichtext, SbBlokData, storyblokEditable } from '@storyblok/react'
import { Disclosure, Transition } from '@headlessui/react'
import { render } from 'storyblok-rich-text-react-renderer'
import padWithLeadingZeros from '@/utils/padWithLeadingZeros'

import styles from './Faq.module.scss'

interface FaqProps {
  blok: SbBlokData & {
    faqBlocks: { question: string; answer: ISbRichtext }[]
  }
}

const Faq = ({ blok }: FaqProps) => {
  return (
    <div {...storyblokEditable(blok)} className={styles.faq__container}>
      <div className={styles.faq__wrapper}>
        {blok.faqBlocks.map((item, index) => (
          <Disclosure
            key={item?.question}
            as="div"
            className={styles.faq__item}
          >
            {({ open }) => (
              <>
                <Disclosure.Button>
                  <small>/ {padWithLeadingZeros(index + 1, 2)}</small>
                  <span className={styles.faq__item__question}>
                    {item?.question}
                  </span>
                  <svg
                    id="expand"
                    data-name="expand"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24.99 31.43"
                    className={clsx(
                      styles.faq__item__icon,
                      open && styles.faq__item__icon__expanded
                    )}
                  >
                    <g>
                      <path
                        fill="currentColor"
                        d="m17.08,10.77c-.09.14-.17.28-.26.42-3.72,6.18-7.45,12.36-11.17,18.54-.48.8-1.13,1.39-2.06,1.61C1.8,31.79-.06,30.31,0,28.48c.02-.66.22-1.25.55-1.8,3.73-6.18,7.45-12.36,11.17-18.54.09-.14.17-.28.26-.43l-.06-.19c-.13.08-.25.19-.39.22-2.45.62-4.9,1.22-7.35,1.83-.82.2-1.62.14-2.34-.3-.98-.6-1.5-1.5-1.46-2.66.06-1.52.88-2.47,2.42-2.86C7.64,2.52,12.48,1.33,17.31.12c1.06-.26,2.05-.12,2.92.57.57.46.88,1.11,1.05,1.81,1.2,4.84,2.39,9.68,3.61,14.51.39,1.55-.44,3.08-1.69,3.65-.82.37-2.21.32-3.07-.44-.57-.51-.89-1.1-1.07-1.8-.61-2.41-1.2-4.82-1.8-7.23-.03-.13-.05-.27-.08-.4-.03,0-.07,0-.1-.01Z"
                      />
                    </g>
                  </svg>
                </Disclosure.Button>
                <Transition
                  show={open}
                  enter={styles.faq__item__enter}
                  enterFrom={styles.faq__item__enterFrom}
                  enterTo={styles.faq__item__leaveFrom}
                  leave={styles.faq__item__leave}
                  leaveFrom={styles.faq__item__leaveFrom}
                  leaveTo={styles.faq__item__enterFrom}
                >
                  <Disclosure.Panel className={styles.faq__item__panel} static>
                    {render(item.answer)}
                  </Disclosure.Panel>
                </Transition>
              </>
            )}
          </Disclosure>
        ))}
      </div>
    </div>
  )
}

export default Faq
