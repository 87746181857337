import { storyblokEditable, type SbBlokData, type ISbRichtext } from '@storyblok/react/rsc'
import Text from '@/components/storyblok/Text'
import styles from './ReviewCard.module.scss'

interface ReviewCardProps {
  blok: SbBlokData & {
    _uid: string
    text: ISbRichtext
    author?: string
    heading?: string
    organisation?: string
  }
}

const ReviewCard = ({ blok }: ReviewCardProps) => (
  <div {...storyblokEditable(blok)} className={styles.reviewcard}>
    <div className={styles.reviewcard__content}>
      {blok.heading && (
        <h3 className={styles.reviewcard__heading}>{blok.heading}</h3>
      )}
      <Text
        wrapperClass={
          styles.reviewcard__content
        }
        animate={false}
        blok={{ text: blok.text }}
      />
      <div className={styles.reviewcard__author}>
        {blok.author}
      </div>
      <div className={styles.reviewcard__organisation}>
        {blok.organisation}
      </div>
    </div>
  </div>
)

export default ReviewCard
