import React from 'react'
import { SbBlokData, storyblokEditable } from '@storyblok/react'
import Card, { CardProps } from './Card'

import styles from './CardGroup.module.scss'

interface CardGroupProps {
  blok: SbBlokData & {
    cards: Array<CardProps['blok']>
  }
}

const CardGroup = ({ blok }: CardGroupProps) => {
  return (
    <div {...storyblokEditable(blok)} className={styles.group}>
      {blok.cards.map((card, index) => (
        <Card key={index} blok={card} />
      ))}
    </div>
  )
}

export default CardGroup
