import {
  storyblokEditable,
  StoryblokComponent,
  SbBlokData,
} from '@storyblok/react/rsc'
import clsx from 'clsx'
import { useInView } from 'react-intersection-observer'
import Particles from '@/components/Particles'
import DotsAnimation from './DotsAnimation'
import BorderAnimation from './BorderAnimation'

import styles from './Section.module.scss'
import { ReactNode } from 'react'

interface SectionProps {
  blok: SbBlokData & {
    internalName: string
    content: SbBlokData[]
    backgroundMedia: SbBlokData[]
    sectionHeader: string
    footer: SbBlokData[]
    backgroundColor:
      | 'background__dots'
      | 'background__linear--bottom'
      | 'background__linear--top'
      | 'background__primary'
      | 'background__radical'
      | 'background__secondary'
      | 'background__transparent'
    borderBottom: false
    borderTop: false
    vAlign: 'top' | 'center' | 'bottom'
    showWaveParticles: boolean
    component: 'section'
  }
  children?: ReactNode
}

const Section = ({ blok, children }: SectionProps) => {
  const { ref, inView } = useInView({
    threshold: 0,
  })

  return (
    <section
      {...storyblokEditable(blok)}
      ref={ref}
      id={blok._uid}
      className={clsx(
        styles.section,
        blok.backgroundColor,
        blok.backgroundMedia?.length && styles.section__background_media
      )}
    >
      <div
        className={clsx(
          styles.section__content,
          styles[`section__align__${blok.vAlign}`]
        )}
      >
        {children ||
          blok.content.map((nestedBlok) => (
            <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
          ))}
        {blok.backgroundColor === 'background__dots' && (
          <DotsAnimation inView={inView} />
        )}
      </div>
      {blok.backgroundMedia?.map((nestedBlok) => (
        <StoryblokComponent
          key={nestedBlok._uid}
          blok={{ ...nestedBlok, autoplay: true, hideControl: true }}
        />
      ))}
      {blok.borderTop && <BorderAnimation from="end" />}
      {blok.borderBottom && <BorderAnimation from="start" />}
      <div className={styles.section__footer}>
        {blok.footer?.map((nestedBlok) => (
          <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
        ))}
      </div>
      {blok.showWaveParticles && <Particles />}
    </section>
  )
}

export default Section
