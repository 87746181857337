import React, { useRef, useEffect } from 'react'
import * as THREE from 'three'

import styles from './Particles.module.scss'

const Particles: React.FC = () => {
  const mountRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const scene = new THREE.Scene()
    const camera = new THREE.PerspectiveCamera(
      60,
      window.innerWidth / window.innerHeight,
      1,
      10000
    )
    const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true }) // Set alpha to true for a transparent background
    const amountX = 40
    const amountY = 100
    const spacer = 20
    const particlesCount = amountX * amountY
    let particles: THREE.Points
    let count = 0

    // Additional parameters for dynamic waves
    const waveFrequencyX = 0.15
    const waveFrequencyY = 0.2
    const waveAmplitude = 75
    const waveSpeed = 0.03

    const animate = () => {
      requestAnimationFrame(animate)
      render()
    }

    const render = () => {
      const positions = particles.geometry.attributes.position.array
      let i = 0
      for (let ix = 0; ix < amountX; ix++) {
        for (let iy = 0; iy < amountY; iy++) {
          positions[i + 1] =
            Math.sin((ix + count) * waveFrequencyX) * waveAmplitude +
            Math.sin((iy + count) * waveFrequencyY) * waveAmplitude
          i += 3
        }
      }
      particles.geometry.attributes.position.needsUpdate = true
      renderer.render(scene, camera)
      count += waveSpeed
    }

    const init = () => {
      renderer.setPixelRatio(window.devicePixelRatio)
      renderer.setSize(window.innerWidth, window.innerHeight)
      if (mountRef.current) {
        mountRef.current.appendChild(renderer.domElement)
      }

      camera.position.x = 750
      camera.lookAt(scene.position)

      const positions = new Float32Array(particlesCount * 3)

      let i = 0
      for (let ix = 0; ix < amountX; ix++) {
        for (let iy = 0; iy < amountY; iy++) {
          positions[i] = ix * spacer - (amountX * spacer) / 2 // x
          positions[i + 1] = 0 // y
          positions[i + 2] = iy * spacer - (amountY * spacer) / 2 // z
          i += 3
        }
      }

      const geometry = new THREE.BufferGeometry()
      geometry.setAttribute('position', new THREE.BufferAttribute(positions, 3))

      particles = new THREE.Points(
        geometry,
        new THREE.PointsMaterial({
          color: 0xfefcf1,
          size: 3,
          transparent: true,
        })
      )

      scene.add(particles)

      renderer.render(scene, camera)
      animate()
    }

    if (mountRef.current) {
      mountRef.current.appendChild(renderer.domElement)
      init()
    }

    return () => {
      // Clean up Three.js scene on component unmount
      if (particles) {
        scene.remove(particles)
      }
      renderer.dispose()
    }
  }, [])

  return <div ref={mountRef} className={styles.particles__wrapper} />
}

export default Particles
