import React from 'react'
import { SbBlokData, storyblokEditable } from '@storyblok/react'
import {
  StackedCarousel,
  ResponsiveContainer,
} from 'react-stacked-center-carousel'
import Image from 'next/image'

import styles from './SliderCoverflow.module.scss'

interface Slide {
  component: 'slides'
  image: {
    alt: string
    filename: string
  }
}
interface SliderCoverflowProps {
  blok: SbBlokData & {
    slide: Slide[]
  }
}

export const Slide = React.memo(function SlideImage(props: {
  data: Slide[]
  dataIndex: number
}) {
  const { data, dataIndex } = props
  const { image } = data[dataIndex]
  return (
    <div className={styles.slidercoverflow__slide}>
      <Image
        draggable={false}
        src={image.filename}
        alt={image.alt}
        className={styles.slidercoverflow__slide__image}
        height={550}
        width={1000}
      />
    </div>
  )
})

const SliderCoverflow = ({ blok }: SliderCoverflowProps) => {
  const ref = React.useRef()
  return (
    <div {...storyblokEditable(blok)} className={styles.slidercoverflow}>
      <ResponsiveContainer
        carouselRef={ref}
        render={(parentWidth, carouselRef) => {
          let currentVisibleSlide = 5
          if (parentWidth <= 1440) currentVisibleSlide = 3
          return (
            <StackedCarousel
              ref={carouselRef}
              slideComponent={Slide}
              slideWidth={parentWidth < 800 ? parentWidth - 60 : 750}
              height={parentWidth / 2}
              carouselWidth={parentWidth}
              data={blok.slide}
              currentVisibleSlide={currentVisibleSlide}
              maxVisibleSlide={5}
              useGrabCursor
              customScales={[1, 0.7, 0.5, 0.2]}
            />
          )
        }}
      />
    </div>
  )
}

export default SliderCoverflow
